import React from "react";
import { VideoPreview } from "../Admin/Courses/ModuleEditor/Preview";
import { BlockPreview } from "../Admin/Courses/ModuleEditor/Preview";
import { QuizPreview } from "../Admin/Courses/ModuleEditor/Preview";
import { ModuleManager } from "./Modules";
import {
  getEnrolledCourses,
  getEnrolledCoursesProgress,
  updateEnrolledCoursesProgress,
} from "../../hooks/courses";
import "./style.css";
import { EnrolledCourses } from "../Profile/Courses";
import { ChartBar, ProgressBar } from "../../components/Charts";
import { PostManager } from "../../components/PostManager";
import { getGlobalClientInfo } from "../../hooks/clientInfo";
import { newUUID } from "../../dateutils/names";
import { CertificateDownloadPage } from "./Certificates";

class MainCourseProgress extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.root = props.root;
    this.state = {
      component: null,
      clientInfo: null,
      data: null,
      currentModule: 0,
      maxModule: 0,
      courseProgress: null,
      posts: [
        {
          image: null,
          caption: "Me parecio genial toda la explicacion",
          entity_type: "courses",
          entity_id: this.data._id,
        },
      ],
    };
  }

  async getCourseData() {
    let clientInfo = await getGlobalClientInfo();
    let data = await getEnrolledCoursesProgress(this.data._id);
    if (data && clientInfo) {
      this.setState(() => ({ courseProgress: data, clientInfo: clientInfo }));
    }
  }

  updateCourseProgress(moduleIndex, pageIndex) {
    let modules = this.state.courseProgress.modules;
    moduleIndex = moduleIndex - 1;

    let moduleToUpdate = modules[moduleIndex];


    if (!moduleToUpdate) {
      moduleToUpdate = {
        maxPage: pageIndex,
        currentPage: pageIndex,
      };
    }

    if (!moduleToUpdate.maxPage) {
      moduleToUpdate["maxPage"] = pageIndex;
    }

    moduleToUpdate["currentPage"] = pageIndex;
    moduleToUpdate["maxPage"] =
      pageIndex > moduleToUpdate["maxPage"]
        ? pageIndex
        : moduleToUpdate["maxPage"];

    if (moduleIndex >= modules.length) {
      modules.push({});
    }
    modules[moduleIndex] = moduleToUpdate;

    let courseProgress = {...this.state.courseProgress};
    courseProgress["modules"] = modules;

    this.updateCourseData(courseProgress);
  }

  async updateCourseData(courseProgress) {
    let response = await updateEnrolledCoursesProgress(
      this.data._id,
      courseProgress
    );

    // if (response) {
    //   // this.setState(() => ({ courseProgress: response }));
    // }
  }

  renderComponent(component, data) {
    this.setState(() => ({ component: component, data: data }));
  }

  openModule(module, index) {
    console.log("opening Module " + index);
    console.log(module);
    this.renderComponent(ModuleManager, { ...module, index: index });
  }

  modules() {
    return (
      <>
        {this.data.modules.map((module, index) => {
          let percent = 0;

          if (this.state.courseProgress) {
            console.log(this.state.courseProgress.modules);
            console.log(module);
            console.log(index);
            let moduleData = this.state.courseProgress.modules[index];
            if (moduleData) {
              console.log(moduleData);
              percent = (moduleData.maxPage * 100) / module.pages.length;
              console.log(percent);
            }
          }

          percent = Math.ceil(percent);

          return (
            <div
              onClick={() => this.openModule(module, index + 1)}
              className="progress-module-card"
            >
              <div className="module-card-header">
                <img className="icon" src={module.icon}></img>
                <h1>{module.title}</h1>
                <p>{percent}%</p>
              </div>
              <div className="module-progress-bar">
                <div style={{ width: `${percent}%` }}></div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  render() {
    if (!this.state.courseProgress) {
      this.getCourseData();
      return <></>;
    }

    if (this.state.component) {
      let Component = this.state.component;
      return (
        <Component
          key={newUUID()}
          root={this}
          data={this.state.data}
        ></Component>
      );
    }

    let calculatedProgress;

    if (this.state.courseProgress) {
      let completness = this.state.courseProgress.modules.length * 100;
      let acumulatedModulesProgress = 0;
      this.state.courseProgress.modules.forEach((moduleProgress, index) => {
        let moduleData = this.data.modules[index];
        let percent = (moduleProgress.maxPage * 100) / moduleData.pages.length;
        acumulatedModulesProgress += percent;
      });

      calculatedProgress = Math.ceil(
        (acumulatedModulesProgress * 100) / completness
      );
    }

    return (
      <div className="animated-intro enrolled-course-container">
         <div className="course-header">
            <img src={this.data.image}></img>

            <div className="course-header-info">
              <div className="header-block">
                <h1>{this.data.name}</h1>
              </div>
              <div className="header-block">
                <h1>6 Horas</h1>

                <p className="course-card-tag">
                  {this.data.tag || "Categoria"}
                </p>

                <div className="share-button" onClick={() => this.share()}>
                  <p>Compartir</p>
                  <img className="icon"></img>
                </div>
              </div>
            </div>
          </div>
        <div className="enrolled-course">
         

          <h1>Descripcion:</h1>

          <pre>{this.data.description}</pre>
          <ProgressBar
            data={{
              metrics: [
                {
                  name: "Haz avanzado un ",
                  units: calculatedProgress,
                  percent: calculatedProgress,
                  max: 100,
                },
              ],
            }}
            label="Progreso"
          ></ProgressBar>

          {calculatedProgress == 100 && (
            <div className="nav-ops">
              <p
                onClick={() =>
                  this.renderComponent(CertificateDownloadPage, {})
                }
              >
                Descargar Certificado
              </p>
            </div>
          )}

          <div className="modules-container">
            <h1>Modulos</h1>
            {this.modules()}
          </div>

          <PostManager data={this.data} entity="courses"></PostManager>

          <div
            className="nav-ops"
            onClick={() => this.root.setComponent(EnrolledCourses, null, {})}
          >
            <p>Volver a cursos</p>
          </div>
        </div>
      </div>
    );
  }
}

export { MainCourseProgress };
