import React from "react";

let optionsMap = (context) => {
  return [
    {
      text: "Ver Detalle",
      icon: "",
      callback: () => {},
    },
    {
      text: "Enviar Mensaje",
      icon: "",
      callback: () => {},
    },
    {
      text: "Generar Cupon",
      icon: "",
      callback: () => {},
    },
    {
      text: "Registrar como Admin",
      icon: "",
      callback: () => {},
    },
  ];
};

class CardMenu extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.options = props.options;
    this.root = props.root;
    this.state = {
      expanded: false,
    };
  }

  render() {
    if (this.state.expanded) {
      return (
        <div className="post-options">
          <img
            className="icon icon-menu"
            onClick={() => this.setState(() => ({ expanded: false }))}
          ></img>
          {this.options.map((option) => {
            return (
              <div
                className="post-menu-option"
                onClick={() => option.callback()}
              >
                <p>{option.text}</p>
                <img className={"icon " + option.icon}></img>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="post-options collapsed">
          <img
            className="icon icon-menu"
            onClick={() => this.setState(() => ({ expanded: true }))}
          ></img>
        </div>
      );
    }
  }
}


class RegisteredUsers extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.root = props.root;
  }

  getOptionsMap() {
    return optionsMap(this);
  }

  render() {
    return (
      <div className="users-management animated-intro">
        <h1>Usuarios Registrados</h1>
        {this.data.users.map((user) => {
          return (
            <div className="user-high-detail">
              <h1>{user.name || "Usuario sin nombre"}</h1>
              <p>{user.email}</p>
              <p>{user.phone}</p>
              <CardMenu
                root={this}
                data={user}
                options={this.getOptionsMap()}
              ></CardMenu>
            </div>
          );
        })}

        <div className="nav-ops">
          <p onClick={()=>this.root.renderComponent(null, null)}>Regresar</p>
        </div>
      </div>
    );
  }
}

export { RegisteredUsers };
