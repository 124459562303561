import React from "react";
import { DeleteAlert } from "./delete";
import { saveAudio } from "../../../../../hooks/audios";
import { saveImage } from "../../../../../hooks/images";
import { BaseEditor } from "./Editors/base";
import { BlockPreview } from "../Preview";
import { newUUID, sleep } from "../../../../../dateutils/names";

let blockTypeMap = {
  title: {
    type: "title",
    text: "Titulo",
  },
  paragraph: {
    type: "paragraph",
    text: "Parrafo",
  },
  audio: {
    type: "audio",
    src: null,
  },
  image: {
    type: "image",
    src: null,
    title: "Titulo imagen",
    description: "Descripcion imagen",
  },
};

class BaseBlock extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.index = props.index;
    this.root = props.root;
    this.blocks = props.blocks;
    let collapsed = this.data.collapsed === false ? false : true;
    this.state = { ...this.data, collapsed: collapsed, edit: false };
  }

  moveUp() {
    this.root.setState((p) => {
      let content = p.content;
      let tmpContent = [...content];
      let newContent = tmpContent.slice(0, this.index - 1);
      newContent.push(content[this.index]);
      newContent.push(content[this.index - 1]);
      newContent.push(...content.slice(this.index + 1));
      return { content: newContent };
    });
  }

  moveDown() {
    this.root.setState((p) => {
      let content = p.content;
      let tmpContent = [...content];
      let newContent = [];
      if (this.index > 0) {
        newContent = tmpContent.slice(0, this.index);
      }
      newContent.push(content[this.index + 1]);
      newContent.push(content[this.index]);
      newContent.push(...content.slice(this.index + 2));
      return { content: newContent };
    });
  }

  deleteBlock() {
    this.root.setState((p) => {
      let content = p.content;
      let tmpContent = [...content];
      let newContent = tmpContent.slice(0, this.index);
      newContent.push(...content.slice(this.index + 1));
      return { content: newContent };
    });
  }

  deleteBlockAlert() {
    this.root.setState(() => ({
      alert: DeleteAlert,
      alertData: {
        title: "Borrar Bloque",
        description: "Esta accion no se puede deshacer, quieres continuar?",
        callback: () => {
          this.deleteBlock();
        },
      },
    }));
  }

  async saveBlock() {

    console.log("Saving Block " + this.index);
    console.log(this.state);
    this.setState(() => ({ edit: false }));
    this.root.setState((p) => {
      let content = [...p.content];
      content[this.index] = { ...this.state, edit: false };
      return { content: content }
    });
    await sleep(200);
    this.root.savePage(false);
  }

  render() {
    if (this.state.collapsed) {
      return this.view();
    } else {
      return (
        <div className="block-container">
          {this.view()}
          {this.state.edit && (
            <div className="block-options">
              <p onClick={() => this.saveBlock()}>Guardar Cambios</p>
            </div>
          )}
          {!this.state.edit && (
            <div className="block-options">
              <img
                className="icon icon-module-delete"
                onClick={() => this.deleteBlockAlert()}
              ></img>
              {this.index > 0 && (
                <img
                  className="icon icon-move-up-light"
                  onClick={() => this.moveUp()}
                ></img>
              )}
              {this.index < this.blocks - 1 && (
                <img
                  className="icon icon-move-down-light"
                  onClick={() => this.moveDown()}
                ></img>
              )}
              <img
                className="icon icon-edit"
                onClick={() => this.setState(() => ({ edit: true }))}
              ></img>
            </div>
          )}
        </div>
      );
    }
  }
}

class TitleBlock extends BaseBlock {
  view() {
    return (
      <>
        {this.state.edit ? (
          <input
            defaultValue={this.state.text}
            onChange={(e) => this.setState(() => ({ text: e.target.value }))}
          ></input>
        ) : (
          <h1>{this.state.text}</h1>
        )}
      </>
    );
  }
}

class ParagraphBlock extends BaseBlock {
  view() {
    return (
      <>
        {this.state.edit ? (
          <textarea
            defaultValue={this.state.text}
            onChange={(e) => this.setState(() => ({ text: e.target.value }))}
          ></textarea>
        ) : (
          <p>{this.state.text}</p>
        )}
      </>
    );
  }
}

class AudioBlock extends BaseBlock {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      ...this.state,
      filename: this.data.filename,
    };
  }

  uploadAudio() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".mp3";
    input.onchange = async (event) => {
      this.setState(() => ({ uploading: true }));
      let file = event.target.files[0];
      let filename = file.name;
      // // console.log(filename);
      let audioURL = await saveAudio(file, this.state.src);
      component.setState(() => ({
        src: audioURL,
        uploading: false,
        filename: filename,
      }));
      await sleep(1000);
      this.saveBlock();
    };
    input.click();
  }

  play() {
    if (this.audioRef.current) {
      if (this.audioRef.current.paused) {
        this.audioRef.current.play();
        this.setState(() => ({ play: true }));
      } else {
        this.audioRef.current.pause();
        this.setState(() => ({ play: false }));
      }
    }
  }

  view() {
    if (this.state.collapsed) {
      return (
        <div className="collapsed-audio-block">
          <div className="audio-status-control">
            <img className="icon icon-audio-play"></img>
            <img className="icon icon-wave-form"></img>
          </div>
          <div className="audio-position-control">
            <p>00:00 / 00:00</p>
            <div className="audio-timeline">
              <div className="audio-timeline-progress"></div>
              <div className="audio-timeline-pointer"></div>
            </div>
          </div>
        </div>
      );
    } else {
      if (this.state.edit) {
        return (
          <div className="audio-block">
            <div className="audio-upload" onClick={() => this.uploadAudio()}>
              <img className="icon icon-add-cover"></img>
              <p>Subir Audio</p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="audio-block">
            <div className="audio-clip">
              <p>{this.state.filename}</p>
              <img
                className={
                  "icon " +
                  (this.state.play ? "icon-pause-flat" : "icon-play-flat")
                }
                onClick={() => this.play()}
              ></img>
            </div>
            <audio ref={this.audioRef}>
              <source src={this.state.src || this.state.raw}></source>
            </audio>
          </div>
        );
      }
    }
  }
}

class ImageBlock extends BaseBlock {
  uploadImage() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.png,.jpeg";
    input.onchange = async (event) => {
      this.setState(() => ({ uploading: true }));
      let imageURL = await saveImage(event.target.files[0], this.state.src);
      component.setState(() => ({ src: imageURL, uploading: false }));
      await sleep(1000);
      this.saveBlock();
    };
    input.click();
  }

  readImage(e) {
    let component = this;
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = (ev) => {
      let contents = ev.target.result;
      component.setState(() => ({ src: contents }));
    };
    reader.readAsDataURL(file);
  }
  view() {
    if (this.state.collapsed) {
      return (
        <div className="collapsed-image-block">
          <img src={this.state.src} alt="block-image"></img>
        </div>
      );
    } else {
      return (
        <div className="image-block">
          {this.state.src ? (
            <div
              className="image-block-upload active"
              onClick={() => this.uploadImage()}
            >
              <img src={this.state.src}></img>
              <p>
                {this.state.uploading
                  ? "Subiendo imagen..."
                  : "Cambiar la imagen"}
              </p>
            </div>
          ) : (
            <div
              className="image-block-upload"
              onClick={() => this.uploadImage()}
            >
              <img className="icon icon-add-cover"></img>
              <p>
                {this.state.uploading
                  ? "Subiendo imagen..."
                  : "Sube una Imagen"}
              </p>
            </div>
          )}
          <div className="image-block-content">
            {this.state.edit ? (
              <input
                defaultValue={this.state.title}
                onChange={(e) =>
                  this.setState(() => ({ title: e.target.value }))
                }
              ></input>
            ) : (
              <h1>{this.state.title}</h1>
            )}
            {this.state.edit ? (
              <textarea
                defaultValue={this.state.description}
                onChange={(e) =>
                  this.setState(() => ({ description: e.target.value }))
                }
              ></textarea>
            ) : (
              <p>{this.state.description}</p>
            )}
          </div>
        </div>
      );
    }
  }
}

class BlockSelector extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
  }

  close() {
    this.root.setState(() => ({ alert: null }));
  }

  addBlock(type) {
    this.root.setState((p) => {
      let content = p.content;
      if (!content) {
        content = [];
      }
      content.push({...blockTypeMap[type]});
      return { content: content, alert: null };
    });
  }

  render() {
    return (
      <div className="module-alert fixed">
        <div className="page-type-options">
          <div
            className="page-type-item"
            onClick={() => this.addBlock("title")}
          >
            <h1>Titulo</h1>
          </div>

          <div
            className="page-type-item"
            onClick={() => this.addBlock("paragraph")}
          >
            <p>parrafo de contenido</p>
          </div>

          <div
            className="page-type-item"
            onClick={() => this.addBlock("image")}
          >
            <h2>Imagen</h2>
            <img className="icon icon-image"></img>
          </div>

          <div
            className="page-type-item"
            onClick={() => this.addBlock("audio")}
          >
            <h2>Clip de Audio</h2>
            <img className="icon icon-wave-short"></img>
          </div>
        </div>
        <button onClick={() => this.close()}>Cancelar</button>
      </div>
    );
  }
}

class BlockEditor extends BaseEditor {
  preview() {
    let data = { ...this.state };
    data["pageIndex"] = this.index;
    this.root.renderPreview(BlockPreview, data);
  }

  mapContent(type, data, index) {
    let dataBlocks = {
      title: TitleBlock,
      paragraph: ParagraphBlock,
      audio: AudioBlock,
      image: ImageBlock,
    };

    let Component = dataBlocks[type];

    return (
      <Component
      key={newUUID()}
        root={this}
        index={index}
        blocks={this.state.content.length}
        data={{ ...data, collapsed: this.state.collapsed }}
      ></Component>
    );
  }

  addBlock() {
    this.setState(() => ({
      alert: BlockSelector,
    }));
  }

  view() {
    if (this.state.collapsed) {
      return (
        <>
          {this.state.content.map((data, index) => {
            return this.mapContent(data.type, data, index);
          })}
        </>
      );
    } else {
      let AlertComponent;
      if (this.state.alert) {
        AlertComponent = this.state.alert;
      }
      return (
        <div className="block-page-content">
          {this.state.alert && (
            <AlertComponent
              root={this}
              data={this.state.alertData}
            ></AlertComponent>
          )}
          <h2>Contenido en bloque</h2>
          <br></br>
          {this.state.content.map((data, index) => {
            return this.mapContent(data.type, data, index);
          })}
          <button onClick={() => this.addBlock()}>Agregar Contenido +</button>
        </div>
      );
    }
  }
}

export { BaseBlock, AudioBlock, ImageBlock, ParagraphBlock, TitleBlock, BlockEditor, BlockSelector };
