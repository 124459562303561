import React from "react";
import { DeleteAlert } from "../delete";
import { sleep } from "../../../../../../dateutils/names";

class BaseEditor extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.index = props.index;
    this.totalPages = props.pages;
    let collapsed = this.data.collapsed === false ? false : true;
    this.state = { ...this.data, collapsed: collapsed };
  }

  async deletePage() {
    if (this.state.type == "video" && this.state.src) {
      let videoId = this.state.src.split("=")[1];
      let req = await fetch(`/api/v1/videos/${videoId}`, {
        method: "DELETE",
      });
    }
    console.log("Delete page");
    if (this.state.type == "block") {
      for (let content of this.state.content) {
        if (content.type == "image" && content.src) {
          let imageId = content.src.split("=")[1];
          let req = await fetch(`/api/v1/images/${imageId}`, {
            method: "DELETE",
          });
        }
      }
    }

    let pages = this.root.state.pages;
    let tmpContent = [...pages];
    tmpContent.splice(this.index, 1);

    this.root.updateModule("pages", tmpContent);
    // this.root.setState((p) => {
    //   let pages = p.pages;
    //   let tmpContent = [...pages];
    //   tmpContent.splice(this.index, 1);
    //   // newContent.push(...pages.splice(this.index + 1));
    //   return { pages: tmpContent };
    // });
  }

  deleteAlert() {
    this.root.setState(() => {
      return {
        alert: DeleteAlert,
        alertData: {
          title: "Estas apunto de eliminar esta pagina",
          description: "Quieres continuar?",
          callback: () => {
            this.deletePage();
          },
        },
      };
    });
  }

  moveUp() {
    // // // // console.log("move_up");
    this.root.setState((p) => {
      let pages = p.pages;
      let tmpContent = [...pages];
      let newContent = tmpContent.slice(0, this.index - 1);
      newContent.push(pages[this.index]);
      newContent.push(pages[this.index - 1]);
      newContent.push(...pages.slice(this.index + 1));
      return { pages: newContent };
    });
  }

  moveDown() {
    // // // // console.log("move_down");
    this.root.setState((p) => {
      let pages = p.pages;
      let tmpContent = [...pages];
      let newContent = [];
      if (this.index > 0) {
        newContent = tmpContent.slice(0, this.index);
      }
      newContent.push(pages[this.index + 1]);
      newContent.push(pages[this.index]);
      newContent.push(...pages.slice(this.index + 2));
      return { pages: newContent };
    });
  }

  // updateState(key, value) {
  //   let newVal = {};
  //   newVal[key] = value;
  //   this.setState(() => newVal);
  // }

  saveData(quit) {
    console.log("Saving Data for " + this.index);
    console.log(this.state);
    this.root.setState((p) => {
      let pages = p.pages;
      pages[this.index] = { ...this.state, collapsed: true };
      return { pages: pages, editPage: quit ? false : true };
    });
  }

  savePage(quit) {
    if (this.state.type == "video") {
      this.saveVideo();
    } else {
      this.saveData(quit);
    }
    this.root.root.saveCourseSilent();
  }

  saveCourse() {
    this.root.root.saveCourseSilent();
  }

  preview() {
    // // // // console.log("Not implemented");
  }

  render() {
    if (this.state.collapsed) {
      return (
        <div className="collapsed-page-card">
          <div className="collapsed-page-header">
            <p>Pagina {this.index + 1}</p>
            <div className="collapsed-page-options">
              <img
                className="icon icon-module-delete"
                onClick={() => this.deleteAlert()}
              ></img>
              <img
                className="icon icon-preview-light"
                onClick={() => this.preview()}
              ></img>
              {this.index > 0 && (
                <img
                  className="icon icon-move-up-light"
                  onClick={() => this.moveUp()}
                ></img>
              )}
              {this.index < this.totalPages - 1 && (
                <img
                  className="icon icon-move-down-light"
                  onClick={() => this.moveDown()}
                ></img>
              )}
              <img
                className="icon icon-page-settings"
                onClick={() =>
                  this.root.setState(() => ({
                    editPage: true,
                    pageIndex: this.index,
                  }))
                }
              ></img>
            </div>
          </div>
          <h2>{this.state.type}</h2>
          {this.view()}
        </div>
      );
    } else {
      return (
        <div className="page-editor">
          <div className="service-main-actions page-editor">
            {this.state.type == "video" ? (
              ! this.state.uploading ? 
              <>
                <div
                  className="preview-btn"
                  onClick={() => {
                    this.savePage(true);
                  }}
                >
                  <p>Guardar</p>
                </div>
              </> : <></>
            ) : (
              <>
                <img
                  className="icon icon-back"
                  onClick={() => {
                    this.savePage(true);
                  }}
                ></img>

                <div className="preview-btn">
                  <p>Vista previa</p>
                  <img className="icon icon-preview"></img>
                </div>
              </>
            )}

            {/* <button onClick={() => this.savePage()}>Guardar</button> */}
          </div>
          <div className="editor-content block">
            <h1>Pagina {this.index + 1}</h1>
            {this.view()}
          </div>
        </div>
      );
    }
  }
}

export { BaseEditor };
