

async function getAppointments() {
    const resp = await fetch('/api/v1/appointments')
    if (resp.status == 200) {
        const data = await resp.json();
        return data.response
    }
}


async function getAppointmentsAdmin(date) {
    let dateQuery = `?year=${date.getFullYear()}&month=${date.getMonth() + 1}&day=${date.getDate()}`;

    const resp = await fetch('/api/v1/appointments/admin' + dateQuery);
    if (resp.status == 200) {
        const data = await resp.json();
        return data.response
    }
}

async function getAppointmentsByMonth(month) {
    let dateQuery = `?filter=month&month=${month}`;

    const resp = await fetch('/api/v1/appointments/admin' + dateQuery);
    if (resp.status == 200) {
        const data = await resp.json();
        return data.response
    }
}

async function getSingleAppointment(id) {
    const resp = await fetch(`/api/v1/appointments?id=${id}`)
    if (resp.status == 200) {
        const data = await resp.json();
        return data.response[0]
    }
}

async function deleteAppointment(id) {
    const resp = await fetch(`/api/v1/appointments/${id}`, 
    {
        method: 'DELETE'
    })
    if (resp.status == 200) {
        const data = await resp.json();
        return data
    }
}

export {
    getAppointments,
    getSingleAppointment,
    deleteAppointment,
    getAppointmentsAdmin,
    getAppointmentsByMonth
};
