async function getCourses(therapist) {
  const resp = await fetch("/api/v1/courses");
  if (resp.status == 200) {
    const data = await resp.json();
    return [data.response, data.accounting_data];
  }
  return [{error: ""}, null];
}

async function getEnrolledCourses() {
  const resp = await fetch("/api/v1/courses/enrolled");
  if (resp.status == 200) {
    const data = await resp.json();
    return data.response;
  }
}

async function getEnrolledCoursesProgress(id) {
  const resp = await fetch(`/api/v1/courses/enrolled/progress?id=${id}`);
  if (resp.status == 200) {
    const data = await resp.json();
    return data.response;
  }
}

async function updateEnrolledCoursesProgress(id, data) {
  const resp = await fetch(`/api/v1/courses/enrolled/progress`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
  if (resp.status == 201) {
    const data = await resp.json();
    return data.response;
  }
}

async function getAdminCourses(therapist) {
  const resp = await fetch("/api/v1/admin/courses");
  if (resp.status == 200) {
    const data = await resp.json();
    return [data.response, data.accounting_data];
  }
}

async function saveCourse(data) {
  const resp = await fetch("/api/v1/admin/courses", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (resp.status == 200) {
    const data = await resp.json();
    return data.course_id;
  }
}

async function deleteCourse(id) {
  const resp = await fetch(`/api/v1/admin/courses/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (resp.status == 200) {
    return;
  }
}

export {
  getCourses,
  saveCourse,
  deleteCourse,
  getAdminCourses,
  getEnrolledCourses,
  getEnrolledCoursesProgress,
  updateEnrolledCoursesProgress
};
