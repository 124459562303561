import React from "react";
import "./style.css";
import { saveImage } from "../../hooks/images";
import { getGlobalClientInfo } from "../../hooks/clientInfo";
import { LoadingComp } from "../../pages/Appointments";
import { sleep } from "../../App";

class PostManager extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.entity = props.entity;
    this.label = props.label || "Posts";
    this.state = {
      posts: null,
      postImage: null,
      rawImage: null,
      postComment: null,
      uploading: false,
      uploadingPost: false,
      addPost: false,
      clientInfo: null,
    };
  }
  async tempSaveimageFile() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.png,.jpeg";
    input.onchange = async (event) => {
      this.setState(() => ({
        postImage: event.target.files[0],
        rawImage: URL.createObjectURL(event.target.files[0])
      }));
      // component.setState((p) => ({}));
    };
    input.click();
  }

  async uploadPost() {
    this.setState(() => ({ uploadingPost: true }));
    await sleep(400);

    let postData = {
      caption: this.state.postComment,
      entity_type: this.entity,
      entity_id: this.data._id,
    };

    if (this.state.postImage) {
      let imageURL = await saveImage(this.state.postImage, null);
      postData["image"] = imageURL;
    }

    let req = await fetch("/api/v1/posts", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(postData),
    });

    if (req.status == 200) {
      let data = await req.json();
      this.setState(() => ({
        posts: null,
        postImage: null,
        rawImage: null,
        postComment: null,
        addPost: false,
        uploading: false,
        uploadingPost: false,
      }));
    }
  }

  async deletePost(postId) {
    let req = await fetch(`/api/v1/posts/${postId}`, {
      method: "DELETE",
    });

    this.setState(() => ({ posts: null }));
  }

  async loadPosts() {
    let req = await fetch(
      `/api/v1/posts?entity_type=${this.entity}&entity_id=${this.data._id}`
    );

    if (req.status == 200) {
      let clientInfo = await getGlobalClientInfo();
      console.log(clientInfo);
      let data = await req.json();
      this.setState(() => ({ posts: data.posts, clientInfo: clientInfo }));
    } else {
      this.setState(() => ({ posts: [] }));
    }
  }

  async clienfInfo() {
    let clientInfo = await getGlobalClientInfo();
    if (clientInfo) {
      this.setState(() => ({
        clientInfo: clientInfo,
      }));
    }
  }

  render() {
    let addPost;

    if (this.state.uploadingPost) {
      return LoadingComp;
    }

    if (!this.state.posts) {
      this.loadPosts();
    }

    if (this.state.addPost) {
      addPost = (
        <div className="add-post animated-intro">
          <img
            className="icon icon-close"
            onClick={() => this.setState(() => ({ addPost: false }))}
          ></img>
          <h1>{this.state.clientInfo.name}</h1>
          <div className="post-input">
            <textarea
              placeholder="Escribe aqui tu comentario"
              onChange={(e) =>
                this.setState(() => ({ postComment: e.target.value }))
              }
            ></textarea>

            <div>
              <img
                onClick={() => this.tempSaveimageFile()}
                className={`icon ${
                  this.state.postImage ? "uploaded-image" : "icon-image-square"
                }`}
                src={
                  this.state.rawImage &&
                  this.state.rawImage
                }
              ></img>
              <img
                className="icon icon-send"
                onClick={() => this.uploadPost()}
              ></img>
            </div>
          </div>
        </div>
      );
    } else {
      addPost = (
        <div
          className="toggle-add animated-intro"
          onClick={() => this.setState(() => ({ addPost: true }))}
        >
          <p>Subir Post</p>
          <img className="icon"></img>
        </div>
      );
    }
    return (
      <div className="posts-container animated-intro">
        <h1>{this.label}</h1>
        {this.state.clientInfo && this.state.clientInfo.name && addPost}
        {this.state.posts &&
          this.state.posts.map((post) => {
            return (
              <div className="post animated-intro">
                <PostMenu
                  root={this}
                  data={{ post: post, clientInfo: this.state.clientInfo }}
                ></PostMenu>
                <div className="user">
                  <img src={post.user && post.user.image}></img>
                  <p>{post.user && post.user.name}</p>
                </div>
                <p>{post.caption}</p>
                {post.image && <img src={post.image}></img>}

                <div className="interactions">
                  <div className="field">
                    <img className="icon icon-fav-product"></img>
                    <p>12</p>
                  </div>
                </div>
                {/* <p onClick={() => this.deletePost(post._id)}>Delete post</p> */}
              </div>
            );
          })}
      </div>
    );
  }
}

class PostMenu extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.root = props.root;
    this.state = {
      expanded: false,
    };
  }

  render() {
    let post = this.data.post;
    if (this.state.expanded) {
      return (
        <div
          className="post-options"
          onBlur={() => this.setState(() => ({ expanded: false }))}
        >
          <img
            className="icon icon-menu"
            onClick={() => this.setState(() => ({ expanded: false }))}
          ></img>

          <div
            className="post-menu-option"
            onClick={async () => {
              if (navigator.share) {
                await navigator.share({
                  title: `Psicologa Marion`,
                  text: this.root.data.name,
                  url: `${location.protocol}//${location.host}/?section=${this.root.entity}&id=${this.root.data._id}`,
                });
              } else {
                window.open(
                  `${location.protocol}//${location.host}/?section=${this.root.entity}&id=${this.root.data._id}`,
                  "_self"
                );
              }
            }}
          >
            <p onClick={() => {}}>Compartir</p>
            <img className="icon icon-share"></img>
          </div>
          {post.user._id &&
            this.data.clientInfo &&
            post.user._id == this.data.clientInfo._id && (
              <div
                className="post-menu-option"
                onClick={() => this.root.deletePost(post._id)}
              >
                <p>Eliminar Post</p>
                <img className="icon icon-delete"></img>
              </div>
            )}
        </div>
      );
    } else {
      return (
        <div className="post-options collapsed">
          <img
            className="icon icon-menu"
            onClick={() => this.setState(() => ({ expanded: true }))}
          ></img>
        </div>
      );
    }
  }
}

export { PostManager };
