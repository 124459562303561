import React from "react";
import { DeleteAlert } from "../delete";
import { BaseEditor } from "./base";
import { QuizPreview } from "../../Preview";
import { newUUID, sleep } from "../../../../../../dateutils/names";

class OptionInput extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = { value: this.data.value };
  }

  close() {
    this.root.setState(() => ({ alert: null }));
  }

  render() {
    return (
      <div className="module-alert fixed">
        <h1>Nueva Opcion</h1>
        <textarea
          defaultValue={this.state.value}
          onChange={(e) => this.setState(() => ({ value: e.target.value }))}
        ></textarea>
        <div className="alert-buttons">
          <button
            style={{ color: "var(--neutral)" }}
            onClick={() => this.close()}
          >
            Cancelar
          </button>
          <button
            onClick={async (e) => {
              // // // console.log(this.state.value);
              this.data.callback(this.state.value);
              await sleep(500);
              this.close();
            }}
          >
            Aceptar
          </button>
        </div>
      </div>
    );
  }
}

class QuizEditor extends BaseEditor {
  preview() {
    let data = { ...this.state };
    data["pageIndex"] = this.index;
    this.root.renderPreview(QuizPreview, data);
  }

  addQuestion() {
    this.setState((p) => {
      let content = p.content;
      if (!content) {
        content = [];
      }
      content.push({
        question: "Edit this",
        options: [],
        answer: null,
      });
      return { content: content };
    });
  }

  deleteOption(qIndex, oIndex) {
    this.setState((p) => {
      let content = p.content;
      if (!content) {
        content = [];
      }
      let question = content[qIndex];

      let options = question.options;
      let tmpContent = [...options];
      let newContent = tmpContent.slice(0, oIndex);
      newContent.push(...options.slice(oIndex + 1));
      question.options = newContent;
      content[qIndex] = question;
      return { content: content };
    });
  }

  deleteOptionDialog(qIndex, oIndex) {
    this.root.setState(() => ({
      alert: DeleteAlert,
      alertData: {
        title: "Borrar Opcion",
        description: "Quieres Continuar?",
        callback: () => {
          this.deleteOption(qIndex, oIndex);
        },
      },
    }));
  }

  addOptionItem(index, value) {
    console.log("Add new option item for index " + index);
    console.log(value);
    console.log(this);
    let content = [...this.state.content];
    if (!content) {
      content = [];
    }
    content[index].options.push(value);
    console.log(content);
    this.setState((p) => ({ content: content }));

    // this.setState((p) => {
    //
    //   return { content: content };
    // });
  }

  addOption(index) {
    let comp = this;
    this.root.setState(() => ({
      alert: OptionInput,
      alertData: {
        value: "",
        callback: (value) => {
          comp.addOptionItem(index, value);
        },
      },
    }));
  }

  updateQuestion(index, key, value) {
    this.setState((p) => {
      let content = p.content;
      content[index][key] = value;
      return {
        content: content,
      };
    });
  }

  saveQuestion(index) {
    this.setState(() => ({ edit: false }));
  }

  deleteQuestion(index) {
    this.setState((p) => {
      let content = p.content;
      let tmpContent = [...content];
      let newContent = tmpContent.slice(0, index);
      newContent.push(...content.slice(index + 1));
      return {
        content: newContent,
      };
    });
  }

  deleteQuestionDialog(index) {
    this.root.setState(() => ({
      alert: DeleteAlert,
      alertData: {
        title: "Borrar Pregunta",
        description: "Quieres Continuar?",
        callback: () => {
          this.deleteQuestion(index);
        },
      },
    }));
  }

  view() {
    if (this.state.collapsed) {
      return (
        <div className="collapsed-quiz-container">
          <h1>{this.state.title}</h1>
          <p>{this.state.description}</p>
          {this.state.content.map((question, index) => {
            return (
              <div>
                <hr></hr>
                <h1>Pregunta {index + 1}</h1>
                <p>{question.question}</p>
                {question.options.map((option) => {
                  return (
                    <div className="multioption-container">
                      <input type="checkbox"></input>
                      <p>{option}</p>
                    </div>
                  );
                })}
                <h1>Respuesta</h1>
                <p style={{ paddingTop: "0 !important" }}>
                  Opcion {question.answer}
                </p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="quiz-block">
          <h2>Prueba Multiopcion</h2>
          <br></br>
          <hr></hr>
          <label>Titulo</label>

          <input
            defaultValue={this.state.title}
            onChange={(e) => this.setState(() => ({ title: e.target.value }))}
          ></input>

          <label>Descripcion</label>

          <textarea
            defaultValue={this.state.description}
            onChange={(e) =>
              this.setState(() => ({ description: e.target.value }))
            }
          ></textarea>
          <br></br>
          <br></br>

          {this.state.content.map((question, index) => {
            return (
              <div>
                <hr></hr>
                <h1>Pregunta {index + 1}</h1>
                {this.state.edit ? (
                  <input
                    defaultValue={question.question}
                    onChange={(e) =>
                      this.updateQuestion(index, "question", e.target.value)
                    }
                  ></input>
                ) : (
                  <p>{question.question}</p>
                )}

                <div className="quiz-answer-bar">
                  <p>opciones</p>
                  <p>respuesta</p>
                  <select
                    defaultValue={question.answer}
                    onChange={(e) => {
                      let content = [...this.state.content];
                      content[index].answer = e.target.value;
                      this.setState((p) => {
                        return { content: content };
                      });
                    }}
                  >
                    {question.options.map((option, opIndex) => (
                      <option value={opIndex + 1}>{opIndex + 1}</option>
                    ))}
                  </select>
                </div>
                {question.options.map((option, opIndex) => {
                  return (
                    <div className="multioption-container-block">
                      <p>{option}</p>
                      <img
                        className="icon icon-delete-option"
                        onClick={() => this.deleteOptionDialog(index, opIndex)}
                      ></img>
                    </div>
                  );
                })}
                <p className="add-option" onClick={() => this.addOption(index)}>
                  Agregar Opcion
                </p>
                <br></br>
                {!this.state.edit && (
                  <div className="block-options">
                    {index > 0 && (
                      <img className="icon icon-move-up-light"></img>
                    )}
                    {index < this.state.content.length - 1 && (
                      <img className="icon icon-move-down-light"></img>
                    )}
                    <img
                      className="icon icon-module-delete"
                      onClick={() => this.deleteQuestionDialog(index)}
                    ></img>
                    <img
                      className="icon icon-edit"
                      onClick={() => this.setState(() => ({ edit: true }))}
                    ></img>
                  </div>
                )}

                {this.state.edit && (
                  <div className="block-options">
                    <p onClick={() => this.saveQuestion(index)}>
                      Guardar Cambios
                    </p>
                  </div>
                )}

                <br></br>
              </div>
            );
          })}

          <button onClick={() => this.addQuestion()}>Agregar Pregunta +</button>
        </div>
      );
    }
  }
}

class SistemicTestEditor extends BaseEditor {
  view() {
    return (
      <div className="collapsed-sistemic-container">
        <h1>{this.state.title}</h1>
        <p>{this.state.description}</p>
        {this.state.content.map((subpage) => {
          return (
            <div className="sistemic-subpage">
              <hr></hr>
              <h1>{subpage.title}</h1>
              {subpage.options.map((option) => {
                return (
                  <div className="collapsed-card-option">
                    <img src={option.image}></img>
                    <h1>{option.title}</h1>
                    <h2 style={{ color: option.colorCode }}>{option.color}</h2>
                    <p>{option.keyword}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export { OptionInput, QuizEditor, SistemicTestEditor };
