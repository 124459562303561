import React from "react";
import "./style.css";
import { getCalendars } from "../../../hooks/calendar";

let eventColors = {
  "1": {
    "background": "#a4bdfc",
    "foreground": "#1d1d1d"
  },
  "2": {
    "background": "#7ae7bf",
    "foreground": "#1d1d1d"
  },
  "3": {
    "background": "#dbadff",
    "foreground": "#1d1d1d"
  },
  "4": {
    "background": "#ff887c",
    "foreground": "#1d1d1d"
  },
  "5": {
    "background": "#fbd75b",
    "foreground": "#1d1d1d"
  },
  "6": {
    "background": "#ffb878",
    "foreground": "#1d1d1d"
  },
  "7": {
    "background": "#46d6db",
    "foreground": "#1d1d1d"
  },
  "8": {
    "background": "#e1e1e1",
    "foreground": "#1d1d1d"
  },
  "9": {
    "background": "#5484ed",
    "foreground": "#1d1d1d"
  },
  "10": {
    "background": "#51b749",
    "foreground": "#1d1d1d"
  },
  "11": {
    "background": "#dc2127",
    "foreground": "#1d1d1d"
  }
}

class GoogleCalendarManager extends React.Component {
  constructor(props) {
    super(props);
    this.userInfo = props.userInfo;
    this.state = {
      expanded: false,
      calendars: null,
      calendar: this.userInfo ? this.userInfo.calendar : null,
      message: "",
    };
  }

  toggle() {
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  async getCalendars() {
    let calendars = await getCalendars();
    if (!calendars) {
      calendars = "No hay calendarios disponibles";
    }
    this.setState(() => ({ calendars: calendars }));
  }

  async updateCalendar() {
    if (!this.state.calendar) {
      return;
    }
    // // console.log('Updating Calendar');
    await fetch("/api/v1/users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        calendar: this.state.calendar,
        message: "Cambios guardados",
      }),
    });
  }

  render() {
    let content;
    let message = this.state.message;

    let calendarOptions;
    if (!this.state.calendars) {
      this.getCalendars();
    } else if (typeof this.state.calendars == "string") {
      calendarOptions = "";
    } else {
      calendarOptions = this.state.calendars.map((calendar) => {
        return <option value={calendar.id}>{calendar.summary}</option>;
      });
    }
    if (this.state.expanded) {
      content = (
        <div className="google-calendar-content">
          <p>{message}</p>
          <label>Tu calendario de Google Para Citas</label>
          <br></br>
          {typeof this.state.calendars == "string" ? (
            <p>{this.state.calendars}</p>
          ) : (
            <select
              value={this.state.calendar ? this.state.calendar : null}
              onChange={(e) =>
                this.setState(() => ({ calendar: e.target.value }))
              }
            >
              {calendarOptions}
            </select>
          )}
          <br></br>
          <p>Calendar Colors</p>
          {/* <div>
            {
              Object.entries(eventColors).map(([key, value])=>{
                return <div><p style={{
                  width: "40px",  height: "40px",
                  backgroundColor: value.background,
                }}>{key}</p>

                <p style={{
                  width: "40px",  height: "40px",
                  backgroundColor: value.foreground,
                }}>{key}</p></div>
              })
            }
            
          </div> */}
          <button onClick={() => this.updateCalendar()}>Guardar Cambios</button>
        </div>
      );
    }
    return (
      <div
        className={`calendar-manager ${this.state.expanded ? "expanded" : ""}`}
      >
        <div className="service-main-actions" onClick={() => this.toggle()}>
          {this.state.expanded ? (
            <>
              <h1>Google Calendar</h1>
              <div>
                <img className="icon icon-collapse-card"></img>
              </div>
            </>
          ) : (
            <>
              <h1>Google Calendar</h1>
            </>
          )}
        </div>
        {this.state.expanded ? content : null}
      </div>
    );
  }
}

export { GoogleCalendarManager };
