import { v4 as uuid } from "uuid";
const monthNames = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const dayNames = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sabado",
];

function newUUID() {
  return uuid();
}

function range(from, to) {
  let arrange = [];
  for (let i = from; i <= to; i++) {
    arrange.push(i);
  }
  return arrange;
}

const formatter = {
  format: (price, addFees=false)=> {
    if (price == 0) {
      return "Gratis!!"
    }
    if (addFees) {
      price = calculatePrice(price)
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  }
}

let COP = new Intl.NumberFormat().format;

function formatPrice(price) {

  if (price.length > 3 && price.length <= 6) {
    let leftStart = 0;
    let leftEnd = price.length - 3;
    let rightStart = price.length - 3;
    let rightEnd = price.length - 1;
    price = `${price.substr(leftStart, leftEnd)}.${price.substr(
      rightStart,
      rightEnd
    )}`;
  }
  return `${price}`;
}

async function sleep(millsecs) {
  return new Promise((resolve) => setTimeout(resolve, millsecs));
}

let paymentFees = { "percent": 3.99 + 1.5, "fixed": 900}

function calculatePrice(priceInput) {
  let price = Number(priceInput);
  return price + (price * paymentFees.percent / 100) + paymentFees.fixed;
}


function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}




export {
  monthNames,
  dayNames,
  formatPrice,
  newUUID,
  COP,
  sleep,
  formatter,
  range,
  calculatePrice,
  capitalizeFirstLetter
};
