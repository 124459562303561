import React from "react";
import TherapyDetail from "../TherapyDetail";
import { WorkshopDetail } from "../Workshops/WorkshopDetail";
import { Course, Courses } from "../Courses";
import { getCourses, getEnrolledCourses } from "../../hooks/courses";
import { Services } from "../Services";
import { Workshops } from "../Workshops";
import { Checkout } from "../../components/Checkout";
import { Horoscope } from "./Horoscope";
import { MainCourseProgress } from "../EnrolledCourse";
import { Tarot } from "../../components/Tarot";
import { getGlobalClientInfo } from "../../hooks/clientInfo";
import { getServices } from "../../hooks/services";
import { Carousel, TallCard } from "../../components/Carousel";
import { TherapistList } from "../../components/Therapists";
import { getGlobaltherapists } from "../../hooks/therapists";
import { getWorkshops } from "../../hooks/workshops";
import "./style.css";
import { newUUID } from "../../dateutils/names";
import { BirthChartExport } from "../../components/BirthChart";


class ExplorePage extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {
      services: null,
      products: null,
      workshops: null,
      courses: null,
      therapist: null,
      therapists: null,
      horoscope: null
    };
  }

  async loadHoroscope() {
    if (this.state.horoscope) {
      return;
    }
    let clientInfo = await getGlobalClientInfo();
    if (clientInfo) {
      console.log(clientInfo);
      if (clientInfo.horoscope) {
        this.setState(()=>({horoscope: {content: clientInfo.horoscope, sign: clientInfo.sign, birth: clientInfo.birth}}))
      }
    }
  }

  async getTherapists() {
    let therapists = await getGlobaltherapists();
    // // console.log(therapists);

    if (!therapists || therapists.length <= 0) {
      return;
    }

    this.setState(() => ({
      therapist: therapists[0]._id,
      therapists: therapists,
    }));
  }

  async setTherapist(therapist) {
    let therapists = await getGlobaltherapists();

    this.setState((p) => {
      return { therapist: therapist, services: null, therapists: therapists };
    });
  }

  async loadResources() {
    if (!this.state.services) {
      let [services, status] = await getServices();
      if (services && !services.error) {
        this.setState(() => ({ services: services }));
      } else if (services && services.error) {
        this.loadResources();
      }
    }

    if (!this.state.workshops) {
      let [workshops, wstatus] = await getWorkshops();
      if (workshops && !workshops.error) {
        let publicWorkshops = [];
        workshops.forEach((workshop) => {
          if (workshop.public) {
            publicWorkshops.push(workshop);
          }
        });
        this.setState(() => ({ workshops: publicWorkshops }));
      } else if (workshops && workshops.error) {
        this.loadResources();
      }
    }

    // if (!this.state.products) {
    //   let [products, pstatus] = await getProducts();
    //   if (products && !products.error) {
    //     this.setState(() => ({ products: products }));
    //   } else if (products && products.error) {
    //     this.loadResources();
    //   }
    // }

    if (!this.state.courses) {
      let [courses, coursesstatus] = await getCourses();
      if (courses && !courses.error) {
        this.setState(() => ({ courses: courses }));
      } else if (courses && courses.error) {
        this.loadResources();
      }
    }

    this.loadHoroscope();
  }

  recomendations() {
    if (this.state.services && this.state.workshops && this.state.courses) {
      return this.state.courses[0];
    }
  }

  render() {
    if (!this.state.services) {
      this.loadResources();
      return (
        <div className="explore-container animated-intro">
          {/* <TherapistList root={this}></TherapistList> */}
        </div>
      );
    }

    let recommended = this.recomendations();

    return (
      <div className="explore-container animated-intro">
        <h1>Recomendados</h1>
        {/* <TherapistList root={this}></TherapistList> */}

        {recommended && (
          <TallCard
            data={recommended}
            labels={[
              {
                text: recommended.purchase ? "Reanudar" : "Comprar",
                class: recommended.purchase ? "extra-option" : "accent-option",
                callback: async () => {
                  if (!recommended.purchase) {
                    this.root.setComponent(
                      Checkout,
                      null,
                      { entityType: "courses", entityId: recommended._id },
                      null
                    );
                  } else {
                    let enrolledCourses = await getEnrolledCourses();
                    enrolledCourses.forEach((course) => {
                      if (course._id == recommended._id) {
                        console.log(course);
                        this.root.setComponent(
                          MainCourseProgress,
                          null,
                          course
                        );
                      }
                    });
                  }
                },
              },
              {
                text: "Mas info",
                callback: () => {
                  this.root.setComponent(Course, null, recommended);
                },
              },
            ]}
          ></TallCard>
        )}

        {
          !recommended && 
          <TallCard></TallCard>
        }

        {this.state.services && (
          <Carousel
            root={this}
            data={{
              list: this.state.services,
              label: "Servicios Terapeuticos",
              labelCallback: () => {
                this.root.setComponent(Services, null, null);
              },
              callback: (option) => {
                this.root.setComponent(TherapyDetail, null, option);
              },
            }}
          ></Carousel>
        )}

        {this.state.workshops && (
          <Carousel
            root={this}
            data={{
              list: this.state.workshops,
              label: "Talleres Presenciales y Online",
              labelCallback: () => {
                this.root.setComponent(Workshops, null, null);
              },
              callback: (option) => {
                this.root.setComponent(WorkshopDetail, null, option);
              },
            }}
          ></Carousel>
        )}

        {/* {this.state.products && (
          <Carousel
            root={this}
            data={{
              list: this.state.products,
              label: "Productos Para el Bienestar",
              labelCallback: () => {
                this.root.setComponent(Shop, null, null);
              },
              callback: (option) => {
                this.root.setComponent(Shop, null, null);
              },
            }}
          ></Carousel>
        )} */}

        {this.state.courses && (
          <Carousel
            root={this}
            data={{
              list: this.state.courses,
              label: "Cursos y Recursos",
              labelCallback: () => {
                this.root.setComponent(Courses, null, null);
              },
              callback: (option) => {
                this.root.setComponent(Course, null, option);
              },
            }}
          ></Carousel>
        )}

        <Horoscope key={newUUID()} data={this.state.horoscope}></Horoscope>

        <BirthChartExport></BirthChartExport>
        
        <Tarot></Tarot>
        
      </div>
    );
  }
}

export { ExplorePage };
