import React from "react";
import "./style.css";
import { Services } from "../../pages/Services";
import { Help } from "../../pages/Help";
import Profile from "../../pages/Profile";
import { Workshops } from "../../pages/Workshops";
import { Courses } from "../../pages/Courses";
import { Shop } from "../Shops";
import { AstroMain } from "../../pages/Astro";
import { cachedTerapists } from "../../hooks/therapists";
import {
  getClientInfo,
  getGlobalClientInfo,
  globalClientInfo,
} from "../../hooks/clientInfo";
import { sleep } from "../../App";
import { ExplorePage } from "../../pages/Explore";

let menuOptions = () => {
  return [
    {
      label: "Talleres",
      icon: "icon-yoga",
      component: Workshops,
    },
    {
      label: "Terapias",
      icon: "icon-chat",
      component: Services,
    },
    {
      label: "Explorar",
      icon: "icon-astrology",
      component: ExplorePage,
    },
    {
      label: "Cursos",
      icon: "icon-courses",
      component: Courses,
    },
    {
      label: "Perfil",
      title: "Espacio Personal",
      icon: "icon-profile-favorite",
      component: Profile,
    },

    // {
    //     label: 'Astro',
    //     icon: 'icon-astrology',
    //     component: AstroMain
    // },
    // {
    //     label: 'Talleres',
    //     icon: 'icon-yoga',
    //     component: Workshops
    // },
    // {
    //     label: 'Terapias',
    //     icon: 'icon-chat',
    //     component: Services
    // },
    // {
    //     label: 'Tienda',
    //     icon: 'icon-shop',
    //     component: Shop
    // },
    // {
    //     label: 'Cursos',
    //     icon: 'icon-courses',
    //     component: Courses
    // },
    // {
    //     label: 'Perfil',
    //     title: 'Espacio Personal',
    //     icon: 'icon-profile-favorite',
    //     component: Profile
    // },
    // {
    //     label: 'Ayuda',
    //     icon: 'icon-help',
    //     component: Help
    // }
  ];
};

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.options = props.options || menuOptions();

    let index = 0;
    this.options.forEach((option, ind) => {
      if (option.label == "Explorar") {
        index = ind;
      }
    });

    this.state = {
      option: props.option || this.options[index],
      clientInfo: null,
    };
  }

  async setDefaultContainerHeight() {
    let menu = document.getElementsByClassName("menu")[0];
    if (menu) {
      let rect = menu.getBoundingClientRect();
      let height = `calc(100vh - ${rect.height}px)`;
      document.documentElement.style.setProperty(
        "--default-container-height",
        height
      );
    }
  }

  async clientInfo() {
    await sleep(1000);
    let info = await getGlobalClientInfo();
    if (info) {
      this.setState(() => ({ clientInfo: info }));
    } else {
      this.setState(() => ({ clientInfo: { error: "no data" } }));
    }
  }

  optionSelected(option) {
    if (option.callback) {
        option.callback(this.root, option);
    } else if (option.component) {
      this.root.setComponent(option.component, option.title, null, option);
      this.setState(() => ({ option: option }));
    }
  }

  render() {
    if (!this.state.clientInfo) {
      this.clientInfo();
    }

    let modifiedMenu = this.options;
    this.setDefaultContainerHeight();

    if (this.state.clientInfo && !this.state.clientInfo.error) {
      modifiedMenu.forEach((option) => {
        if (option.label == "Astro") {
          option.icon = "icon-" + this.state.clientInfo.sign;
        }
      });
    }

    let options = modifiedMenu.map((option) => {
      let selected =
        option.label == this.state.option.label ? "menu-selected" : "";
      return (
        <div
          className={`menu-item ${selected}`}
          onClick={() => this.optionSelected(option)}
        >
          <img className={`icon ${option.icon}`}></img>
          <p>{option.label}</p>
        </div>
      );
    });

    let menuGridStyle = `repeat(${this.options.length}, ${
      100 / this.options.length
    }vw)`;

    return (
      <div className="menu" style={{ gridTemplateColumns: menuGridStyle }}>
        {options}
      </div>
    );
  }
}

export { Menu, menuOptions };
