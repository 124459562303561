import React from "react";
import "./style.css";

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
  }

  async callback(option) {
    this.data.callback(option);
  }

  async labelCallback() {
    this.data.labelCallback();
  }

  render() {
    console.log(this.data.list);
    return (
      <div className="carousel-container">
        <h1 onClick={() => this.labelCallback()}>{this.data.label}</h1>
        <div className="scroll-container">
          {this.data.list &&
            this.data.list.map &&
            this.data.list.map((element) => {
              return (
                <div
                  className="carousel-item"
                  onClick={() => this.callback(element)}
                >
                  <img src={element.image}></img>
                  <h1>{element.name}</h1>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

class TallCard extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.labels = props.labels;
  }

  render() {
    console.log(this.data);
    return (
      <div className="tall-card-container animated-intro">
        <div className="tall-card">
          {this.data && (
            <>
              <div className="tall-card-cover">
                <img src={this.data.image}></img>
              </div>
              <img
                className="icon icon-share"
                onClick={async () => {
                  if (navigator.share) {
                    await navigator.share({
                      title: `${this.data.name}`,
                      text: this.data.name,
                      url: `${location.protocol}//${location.host}/?section=courses&id=${this.data._id}`,
                    });
                  } else {
                    window.open(
                      `${location.protocol}//${location.host}/?section=courses&id=${this.data._id}`,
                      "_self"
                    );
                  }
                }}
              ></img>
              <div className="tall-card-info">
                <h1>{this.data.name}</h1>
                <p>{this.data.description}</p>
                <div>
                  {this.labels.map((label, index) => {
                    return (
                      <p
                        className={label.class}
                        onClick={() => label.callback()}
                      >
                        {label.text}
                      </p>
                    );
                  })}
                  {/* <p className="accent-option">Reservar</p>
              <p>Mas info</p> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export { Carousel, TallCard };
