import React from 'react';
import './style.css';

class CertificateDownloadPage extends React.Component {
    constructor(props) {
        super(props);
        this.data = props.data;
        this.root = props.root;
    }

    render() {
        console.log(this.root.data);
        return (

            <div className="download-certificate-page">
                <h1>Felicidades!</h1>
                <p>Haz Completado todo el curso</p>

                <p>Ahora puedes descargar tu certificado o pedirlo impreso y enmarcado para tu casa.</p>

                <div className="certificate-download">
                    <img src={this.root.data.certificate.image}></img>
                    <div className="download-options">
                        <div>
                            <img className="icon icon-download"></img>
                        </div>
                    </div>
                </div>
                <div className="nav-ops">
                    <p className="extra-option">Pedir Version impresa</p>
                    <p onClick={()=>this.root.setState(()=>({component: null, data: null}))}>Regresar al curso</p>
                </div>
            </div>
        )
    }
}

export {
    CertificateDownloadPage
}