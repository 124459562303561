async function getCalendars() {
    let req = await fetch('/api/v1/admin/calendar');

    if (req.status == 200) {
        let data = await req.json();
        return data.calendars;
    }
}

async function getHoroscope() {
    let req = await fetch('/horoscope');

    if (req.status == 200) {
        let data = await req.json();
        return data.response;
    }
}

export {
    getCalendars,
    getHoroscope
}