import React from "react";
import "./style.css";
import { monthNames, newUUID } from "../../../dateutils/names";
import { DateDetail } from "..";
import { getAppointmentsByMonth } from "../../../hooks/appointments";
import { sleep } from "../../../dateutils/names";

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

class Month extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.month = props.month;
    this.state = {
      appointments: null,
    };
  }

  async getAppointments() {
    let appointmentsByMonth = await getAppointmentsByMonth(this.month);
    console.log(appointmentsByMonth);
    this.setState(() => ({ appointments: appointmentsByMonth }));
  }

  render() {
    if (!this.state.appointments) {
      this.getAppointments();
      return <></>;
    }
    let today = new Date(Date.now());

    let days = new Array(daysInMonth(this.month - 1, 2025)).fill("");
    let padding;

    let firstDayInMonth = new Date(2025, this.month - 1, 1);

    padding = new Array(firstDayInMonth.getDay()).fill(
      <div className="day-cell"></div>
    );

    return (
      <div
        className="month-container"
        id={`${today.getFullYear()}-${this.month}`}
      >
        <h1>{monthNames[this.month - 1]}</h1>
        <div className="month-grid">
          <div className="day-cell cell-header">
            <h1>S</h1>
          </div>
          <div className="day-cell cell-header">
            <h1>M</h1>
          </div>
          <div className="day-cell cell-header">
            <h1>T</h1>
          </div>
          <div className="day-cell cell-header">
            <h1>W</h1>
          </div>
          <div className="day-cell cell-header">
            <h1>T</h1>
          </div>
          <div className="day-cell cell-header">
            <h1>F</h1>
          </div>
          <div className="day-cell cell-header">
            <h1>S</h1>
          </div>
          {padding}
          {days.map((day, index) => {
            let appointmentsByDay = this.state.appointments.filter(
              (app) => app.date.day == index + 1
            );

            return (
              <div className="day-cell">
                <h1
                  className={(today.getDate() == index + 1 && today.getMonth() + 1 == this.month) ? "current-day" : ""}
                >
                  {index + 1}
                </h1>
                {appointmentsByDay.map((app, appIndex) => {
                  return (
                    <p
                      onClick={() => {
                        this.root.renderComponent(DateDetail, app);
                      }}
                    >
                      {app.user.name}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class FullCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {
      component: null,
      data: null,
    };
  }

  async scrollToMonth(year, month) {
    await sleep(1000);
    let selector = `div[id="${year}-${month}"]`;

    let el = document.querySelector(selector);

    console.log(selector);
    console.log(el);

    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else {
        this.scrollToMonth(year, month);
    }
  }

  renderComponent(component, data) {
    this.setState(() => ({ component: component, data: data }));
  }

  renderMonths() {
    let today = new Date(Date.now());
    let months = [
        today.getMonth() == 0 ? 12 : today.getMonth(),
        today.getMonth() + 1,
        today.getMonth() + 2,
        today.getMonth() + 3,
        today.getMonth() + 4
    ]
    console.log(months)

    return months.map((month) => {
        return <Month root={this} month={month} key={newUUID()}></Month>
    })
  }

  render() {
    if (this.state.component) {
      let Component = this.state.component;
      return (
        <Component
          data={this.state.data}
          root={this}
          key={newUUID()}
        ></Component>
      );
    }
    let today = new Date(Date.now());
    this.scrollToMonth(today.getFullYear(), today.getMonth() + 1);

    return (
      <div className="calendar-page">
        <div className="nav-header">
          <img
            className="icon icon-back"
            onClick={() => this.root.renderComponent(null, null)}
          ></img>
        </div>
        <div className="months-scroll">
            {
                this.renderMonths()
            }
          {/* <Month root={this} month={1} key={newUUID()}></Month>

          <Month root={this} month={2} key={newUUID()}></Month>
          <Month root={this} month={3} key={newUUID()}></Month> */}
        </div>
      </div>
    );
  }
}

export { FullCalendar };
