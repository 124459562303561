import React from "react";
import { formatter, newUUID } from "../../dateutils/names";
import "./style.css";
import { BaseComponent } from "./base";
import { AdminDetail } from "./Admins";
import { getCalendars } from "../../hooks/calendar";
import { getMenuByLabel, sleep } from "../../App";
import { RegisteredUsers } from "./Users";
import { Menu } from "../../components/Menu";



class AlertWindow extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = {}
  }

  render() {
    return (<div className="management-alert" style={{backgroundColor: this.data.color}}>
      <img className="icon icon-close" onClick={()=>this.root.setState(()=>({alert: null}))}></img>
      <h1>{this.data.title}</h1>
      <p>{this.data.description}</p>
    </div>)
  }
}

let alertTemplate = {
  title: "Esto es un alert",
  description: "El mensaje que se muestra en el alert que debe ser un poco mas largo que el titulo para hacer enfasis",
  color: "var(--second)"
};

class SuperAdminMain extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      admins: null,
      users: null,
      calendars: null,
      whatsappStatus: null,
      metrics: {
        totalSales: 1000000,
        marketPlaceProfit: 15000,
        boldCosts: 39900,
        deliveryCosts: 24000,
      },
    };
  }

  async checkWhatsAppStatus(commit = false) {
    let payload = {};
    if (!commit) {
      payload["code"] = true;
    }
    let resp = await fetch("/api/v1/admin/auth_manager_status", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    if (resp.status == 200) {
      let data = (await resp.json()).response;
      if (commit) {
        this.setState(() => ({ whatsappStatus: data }));
      } else {
        return data;
      }
    }
  }

  async getAdmins() {
    let request = await fetch(`/api/v1/management/admin-data`);
    let calendar = await this.checkGoogleSignIn();
    this.checkWhatsAppStatus(true);

    if (request.status == 200) {
      let data = await request.json();
      this.setState(() => ({
        admins: data.metrics,
        users: data.users,
        calendars: calendar,
      }));
      console.log(request);
    } else if (request.status == 401) {
      window.open("/", "_self");
    }
  }

  async getAdminsTest() {
    this.setState(() => ({
      admins: [
        {
          _id: "123456",
          name: "Test admin name",
          professional_name: "",
          image: null,
          status: 1,
        },
        {
          _id: "123456",
          name: "Another test admin",
          professional_name: "",
          image: null,
          status: 1,
        },
      ],
    }));
  }

  async checkGoogleSignIn() {
    let calendars = await getCalendars();
    return calendars;
  }

  async adminLogin() {
    let resp = await fetch("/api/v1/auth/admin/login");
    if (resp.status == 200) {
      let data = await resp.json();
      window.open(data.url, "_self");
    }
  }

  async checkWhatsAppUntilLogged() {
    await sleep(6000);
    let status = await this.checkWhatsAppStatus();
    if (!status.status) {
      this.checkWhatsAppUntilLogged();
    } else {
      this.setState(() => ({ whatsappStatus: status }));
    }
  }

  async enableWhatsApp() {
    this.setState(() => ({ whatsappStatus: { status: false, code: "image" } }));

    let resp = await fetch("/api/v1/admin/init_auth_manager", {
      method: "POST",
    });
    if (resp.status == 200) {
      let data = (await resp.json()).response;
      this.checkWhatsAppUntilLogged();
      this.setState(() => ({ whatsappStatus: data }));
    }
  }

  renderWhatsapp() {
    return (
      <div className="google-services-card">
        <h1>
          WhatsApp{" "}
          {!this.state.whatsappStatus
            ? "Cargando Estado..."
            : !this.state.whatsappStatus.status
            ? "Disabled"
            : "Enabled"}
        </h1>
        {this.state.whatsappStatus && !this.state.whatsappStatus.status && (
          <button onClick={() => this.enableWhatsApp()}>Start</button>
        )}

        {this.state.whatsappStatus &&
          !this.state.whatsappStatus.status &&
          this.state.whatsappStatus.code && (
            <div className="code-whatsapp">
              <img
                className="icon icon-close"
                onClick={() => {
                  this.setState((p) => {
                    return {
                      whatsappStatus: {
                        status: p.whatsappStatus.status,
                        code: null,
                      },
                    };
                  });
                }}
              ></img>
              <p>
                {this.state.whatsappStatus.code == "image"
                  ? "Estamos cargando el codigo QR ten listo tu telefono para escanearlo"
                  : "Codigo QR"}
              </p>
              <img src={this.state.whatsappStatus.code}></img>
            </div>
          )}
        {this.state.whatsappStatus && !this.state.whatsappStatus.status && (
          <div>
            <p>
              Ten listo WhatsApp en tu telefono "Settings / Linked Devices", te
              mostraremos un codigo que debes escanear
            </p>
          </div>
        )}

        {this.state.whatsappStatus && this.state.whatsappStatus.status && (
          <>
            <div className="messaging-options">
              <h1>Cuenta Activa: +57 3176923176</h1>
              <p className="extra-option">Enviar mensaje a usuarios</p>
              <p>Ver Logs</p>
              <p className="">Desconectar cuenta</p>
            </div>
          </>
        )}
      </div>
    );
  }

  renderAdmins() {
    return (
      <div>
        <br></br>
        <h1>Administradores Registrados</h1>
        {this.state.admins.map((admin) => {
          return (
            <div
              onClick={async () => {
                await sleep(300);
                this.root.renderComponent(AdminDetail, admin);
              }}
              className="admin-card"
            >
              <img src={admin.profile_image}></img>
              <p>{admin.name}</p>
              <h1>{adminStatus[admin.status]}</h1>
            </div>
          );
        })}
      </div>
    );
  }

  renderUsers() {
    return (
      <>
        <br></br>
        <h1>Adquisicion</h1>

        <div className="google-services-card" onClick={async ()=>{
          await sleep(300);
          this.root.renderComponent(RegisteredUsers, {users:this.state.users});
        }}>
          <h1>
            Usuarios Registrados ({this.state.users && this.state.users.length})
          </h1>

          {/* <div className="">
          {this.state.users &&
            this.state.users.map((user) => {
              return (
                <div className="user-low-detail">
                  <p>{user.name}</p>
                </div>
              );
            })}
        </div> */}
        </div>
      </>
    );
  }

  renderManagementOptions() {
    return (
      <div>
        <br></br>
        <h1>Administracion de Recursos</h1>
        <div
          className="management-card-option"
          onClick={async () => {
            this.setState(()=>({alert: {
              title: "Iniciando respaldo de la db",
              description: "Esto puede tomar un tiempo",
              color: "var(--second)"
            }}))
            let resp = await fetch("/api/v1/admin/dumpdb", {
              method: "POST",
            });
            if (resp.status == 200) {
              console.log(await resp.json());
              this.setState(()=>({alert: {
                title: "Operacion completa",
                description: "La base de datos ha sido respaldada",
                color: "var(--second)"
              }}))
            }
          }}
        >
          <img className="icon"></img>
          <p>Respaldar DB</p>
        </div>

        <div
          className="management-card-option"
          onClick={async () => {
            this.setState(()=>({alert: {
              title: "Iniciando respaldo de la media...",
              description: "Esto puede tomar un tiempo",
              color: "var(--second)"
            }}))
            let resp = await fetch("/api/v1/admin/dumpmedia", {
              method: "POST",
            });
            if (resp.status == 200) {
              console.log(await resp.json());
              await sleep(400);
              this.setState(()=>({alert: {
                title: "Operacion completa",
                description: "Todas las imagenes y videos fueron comprimidos y guardados",
                color: "var(--second)"
              }}))
            }
          }}
        >
          <img className="icon"></img>
          <p>Respaldar Media</p>
        </div>

        <div
          className="management-card-option"
          onClick={async () => {
            let resp = await fetch("/api/v1/admin/restoredb", {
              method: "POST",
            });
            if (resp.status == 200) {
              console.log(await resp.json());
            }
          }}
        >
          <img className="icon"></img>
          <p>Cargar DB</p>
        </div>

        <div className="management-card-option">
          <img className="icon"></img>
          <p>Cargar Media</p>
        </div>
      </div>
    );
  }

  renderSales() {
    return (
      <div>
        <h1>Resumen de Ventas</h1>
        <div className="currency-chart">
          <h1>Total Sales</h1>
          <p>{formatter.format(this.state.metrics.totalSales)}</p>
        </div>
        <div className="currency-chart">
          <h1>Comissiones Bold</h1>
          <p>{formatter.format(this.state.metrics.boldCosts)}</p>
        </div>
        <div className="currency-chart">
          <h1>Marketplace Profit</h1>
          <p>{formatter.format(this.state.metrics.marketPlaceProfit)}</p>
        </div>
        <div className="currency-chart">
          <h1>Shipment Costs</h1>
          <p>{formatter.format(this.state.metrics.deliveryCosts)}</p>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.admins) {
      this.getAdmins();
      return <></>;
    }

    return (
      <div className="super-admin-main animated-intro">
        {
          this.state.alert && 
          <AlertWindow key={newUUID()} root={this} data={this.state.alert}></AlertWindow>
        }
        <div className="google-services-card">
          <h1>
            Google Services: {this.state.calendars ? "Enabled" : "Disabled"}
          </h1>
          {!this.state.calendars && (
            <button onClick={() => this.adminLogin()}>Sign In</button>
          )}
        </div>
        {this.renderWhatsapp()}

        <br></br>

        {this.renderAdmins()}
        {this.renderUsers()}
        <br></br>
        {this.renderSales()}
        {this.renderManagementOptions()}
        <div className="nav-ops">
          <p onClick={() => window.open("/admin", "_self")}>Salir</p>
        </div>
      </div>
    );
  }
}

let adminStatus = ["registrado", "activo", "inactivo"];

class SuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component: SuperAdminMain,
      data: {},
    };
  }

  renderComponent(component, data) {
    if (!component) {
      component = SuperAdminMain;
    }
    this.setState(() => ({ component: component, data: data }));
  }

  render() {
    // this.loadData();
    let Component = this.state.component;
    let componentView = (
      <Component root={this} data={this.state.data}></Component>
    );

    return <div className="super-admin animated-intro">{componentView}
      <Menu
          options={superAdminOptions}
          key={newUUID()}
          root={this}
          option={getMenuByLabel("Super Admin", superAdminOptions)}
        ></Menu></div>;
  }
}


let superAdminOptions = [
  {
    label: "WebApp",
    icon: "icon-yoga",
    callback: ()=>window.open("/", "_self")
  },
  {
    label: "Admin",
    icon: "icon-admin",
    callback: ()=>window.open("/admin", "_self")
  },
  {
    label: "Super Admin",
    icon: "icon-super-admin",
    callback: ()=>window.open("/management", "_self")
  },
  {
    label: "Mensajes",
    icon: "icon-chat",
    callback: ()=>window.open("/", "_self")
  },
]

export { SuperAdmin };
