import Rect from "react";
import "../TherapyDetail/styles.css";
import { sleep } from "../../App";
import { COP, formatPrice, formatter } from "../../dateutils/names.js";
import { mockServices } from "../../constants.js";
import { Services } from "../Services/index.js";
import { PacksConfig } from "../Packs/index.js";
import AppointmentsConfig from "../Appointments/index.js";
import { ExplorePage } from "../Explore/index.js";
import { getCurrentTherapistInfo, getTherapists, setCurrentTherapist } from "../../hooks/therapists.js";

class TherapyDetail extends Rect.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.therapist = this.data.clientInfo;
    this.state = {
      renderTest: false,
      therapist: this.data.clientInfo,
    };
  }

  back() {
    window.open(`/`, "_self");
  }

  renderTest() {
    this.setState((p) => {
      return {
        renderTest: true,
      };
    });
  }

  async getTherapist() {
    let therapists = await getTherapists();
  
    console.log(therapists);

    let therapist = therapists.filter((t)=>t._id == this.data.owner)[0]
    await setCurrentTherapist(therapist);
    
    if (therapist) {
      this.setState(() => ({ therapist: therapist }));
    }
  }

  render() {
    if (!this.state.therapist) {
      this.getTherapist();
      return <></>;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");

    let detail = this.data;
    let test;

    if (this.state.renderTest) {
      test = (
        <div className="therapy-test">
          <span
            className="icon icon-back"
            onClick={(e) => this.setState((p) => ({ renderTest: false }))}
          ></span>
          <h1>Test de guia</h1>
        </div>
      );
    }

    let plans = detail.plans.map((plan, index) => {
      return (
        <div
          key={plan.price}
          onClick={(e) => {
            if (plan.sessions == 1 || !plan.sessions) {
              this.root.setComponent(AppointmentsConfig, null, {
                service: detail,
                plan: plan,
              });
            } else {
              this.root.setComponent(PacksConfig, null, {
                service: detail,
                plan: plan,
                planIndex: index,
              });
            }
          }}
          className={`plan plan-${index}`}
        >
          <h1>
            {plan.sessions} {plan.sessions == 1 ? "Sesion de " : "Sesiones de "}
            {plan.sessions == 1
              ? `${detail.time} minutos`
              : `${detail.time} min`}
          </h1>
          <h1>{formatter.format(plan.price, true)}</h1>
        </div>
      );
    });

    return (
      <div className="therapy-detail animated-intro">
        {test}
        <div className="detail-header">
          <img className="cover" src={detail.image}></img>
          <h1>{detail.name}</h1>
          <img
            className="icon icon-share"
            onClick={async () => {
              if (navigator.share) {
                await navigator.share({
                  title: `${detail.name}`,
                  text: detail.name,
                  url: `${location.protocol}//${location.host}/?section=services&id=${detail._id}`,
                });
              } else {
                window.open(`${location.protocol}//${location.host}/?section=services&id=${detail._id}`, "_self");
              }
            }}
          ></img>
        </div>
        <div className="detail-content">
          <div className="detail-therapist">
            <img src={this.state.therapist.profile_image}></img>
            <h1>{this.state.therapist.professional_name}</h1>
          </div>
          <p>{detail.description}</p>
          <h1>Elige tu opcion</h1>
          {plans}
          <div className="nav-ops">
            <p onClick={() => this.root.setComponent(Services)}>Regresar</p>
          </div>
        </div>
      </div>
    );
  }
}

export default TherapyDetail;
