import React from "react";
import { countryCodes } from "../../pages/Profile/constants";
import { SearchList } from "../Search";
import { newUUID, sleep } from "../../dateutils/names";
import { getCities } from "../../hooks/clientInfo";
import { range } from "../../dateutils/names";
import { monthNames } from "../../dateutils/names";
import "./style.css";

let inputDataMap = {
  name: { placeholder: "Tu Nombre" },
};

class BirthChartExport extends React.Component {
  constructor(props) {
    super(props);

    let date = new Date();

    this.state = {
      name: null,
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      country: null,
      city: "Bogota",
      dial_code: "+57",
      cities: null,
      result: null,
      savedResult: null,
      expand: false,
    };
  }

  async loadCities() {
    this.updateInput("dial_code", "+57");
  }

  async updateInput(key, value) {
    let cities = this.state.cities;
    if (!cities) {
      cities = [];
    }
    if (key == "dial_code") {
      let country = countryCodes.filter((c) => value == c.dial_code)[0];
      cities = await getCities(country.name);
    }
    this.setState(() => {
      let updateValue = {};
      updateValue[key] = value;
      if (cities) {
        updateValue["cities"] = cities;
      }
      return updateValue;
    });
  }

  async calculate(PDF) {
    let payload = {
      country: countryCodes.filter(
        (c) => this.state.dial_code == c.dial_code
      )[0].code,
      city: this.state.city,
      name: this.state.name,
      day: this.state.day,
      month: this.state.month,
      year: this.state.year,
      hour: this.state.hour,
      minute: this.state.minute,
    };

    if (PDF) {
      payload["PDF"] = true;
    }
    console.log(payload);

    let response = await fetch("/birth-chart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.status == 200) {
      let blob = await response.blob();
      let result = window.URL.createObjectURL(blob);
      if (PDF) {
        let link = document.createElement("a");
        link.href = result;
        link.download = `birth-chart-${payload.name}.pdf`;
        link.click();

        link.remove();
      } else {
        this.setState(() => ({ result: result }));
      }
    }
  }

  render() {
    if (!this.state.cities) {
      this.loadCities();
      return <></>;
    }
    if (this.state.result) {
      return (
        <div className="birth-chart exp animated-intro">
          <img
            className="icon icon-back"
            onClick={() =>
              this.setState((p) => ({
                expand: false,
                result: null,
                savedResult: p.result,
              }))
            }
          ></img>
          <h1>Carta Natal</h1>
          <div className="birth-chart-input">
            <img src={this.state.result}></img>
            <button onClick={() => this.calculate(true)}>Descargar PDF</button>
          </div>
          <button
            className="accent-option"
            onClick={() =>
              this.setState((p) => ({ result: null, savedResult: p.result }))
            }
          >
            Reiniciar calculadora
          </button>
        </div>
      );
    }
    let birthInfo;
    birthInfo = (
      <div className="birth-chart-input">
        <h1>Fecha de Nacimiento</h1>
        <div className="birth-option">
          <p>Dia</p>
          <select
            onChange={(e) => this.updateInput("day", e.target.value)}
            value={this.state.day}
          >
            {range(1, 31).map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
        <div className="birth-option">
          <p>Mes</p>
          <select
            onChange={(e) => this.updateInput("month", e.target.value)}
            value={this.state.month}
          >
            {monthNames.map((month, index) => {
              return (
                <option key={month} value={index}>
                  {month}
                </option>
              );
            })}
          </select>
        </div>
        <div className="birth-option">
          <p>Año</p>
          <select
            onChange={(e) => this.updateInput("year", e.target.value)}
            value={this.state.year}
          >
            {range(1930, 2025)
              .reverse()
              .map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </select>
        </div>
        <div className="birth-option">
          <p>Hora</p>
          <select
            onChange={(e) => this.updateInput("hour", e.target.value)}
            value={this.state.hour}
          >
            {range(0, 24)
              .reverse()
              .map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
          </select>
        </div>
        <div className="birth-option">
          <p>Minuto</p>
          <select
            onChange={(e) => this.updateInput("minute", e.target.value)}
            value={this.state.minute}
          >
            {range(0, 60)
              .reverse()
              .map((minute) => (
                <option key={minute} value={minute}>
                  {minute}
                </option>
              ))}
          </select>
        </div>
      </div>
    );

    if (!this.state.expand) {
      return (
        <div
          className="birth-chart animated-intro"
          onClick={async () => {
            await sleep(300);
            this.setState(() => ({ expand: true }));
          }}
        >
          <img className="icon icon-birth-chart"></img>
          <p>Descubre tu carta Natal</p>
        </div>
      );
    }
    return (
      <div className="birth-chart exp animated-intro">
        <img
          className="icon icon-back"
          onClick={() => this.setState(() => ({ expand: false }))}
        ></img>
        <h1>Carta Natal</h1>
        {birthInfo}

        <SearchList
          key={newUUID()}
          root={this}
          callback="updateInput"
          label="Codigo Pais"
          value="dial_code"
          keys={["dial_code", "name"]}
          list={countryCodes}
          default={this.state.dial_code || dial_code}
        ></SearchList>

        <SearchList
          key={newUUID()}
          list={this.state.cities}
          label="Ciudad"
          root={this}
          value="city"
          callback="updateInput"
          default={this.state.city}
        ></SearchList>
        <br></br>

        {Object.entries(inputDataMap).map(([key, datamap]) => {
          return (
            <input
              placeholder={datamap.placeholder}
              onChange={(e) => this.updateInput(key, e.target.value)}
            ></input>
          );
        })}

        <button onClick={() => this.calculate()}>Calcular</button>
        {this.state.savedResult && (
          <button
            className="accent-option"
            onClick={() => this.setState((p) => ({ result: p.savedResult }))}
          >
            Ver Resultado Anterior
          </button>
        )}
      </div>
    );
  }
}

export { BirthChartExport };
