import React from "react";
import "./style.css";
import { getEnrolledCourses } from "../../../hooks/courses";
import { MainCourseProgress } from "../../EnrolledCourse";
import Profile from "..";

class EnrolledCourses extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {
      courses: null,
    };
  }

  async getCourses() {
    let courses = await getEnrolledCourses();
    if (courses) {
      this.setState(() => ({ courses: courses }));
    }
  }

  openCourse(course) {
    this.root.setComponent(MainCourseProgress, "", course);
  }

  renderCoursesCards() {
    return (
      <>
        {this.state.courses.map((course) => {
          return (
            <div
              className="enrolled-course-card"
              onClick={() => this.openCourse(course)}
            >
              <img src={course.image}></img>
              <h1>{course.name}</h1>
              <p>100%</p>
            </div>
          );
        })}
      </>
    );
  }

  render() {
    if (!this.state.courses) {
      this.getCourses();
      return (
        <div className="enrolled-courses">
          <h1>Cargando...</h1>
        </div>
      );
    }
    return (
      <div className="enrolled-courses">
        <LevelBadge></LevelBadge>
        <h1>Cursos en progreso</h1>

        <div className="enrolled-courses-list">{this.renderCoursesCards()}</div>
        <div className="nav-ops">
          <p
            onClick={() => this.root.setComponent(Profile, "Espacio Personal")}
          >
            Regresar
          </p>
        </div>
      </div>
    );
  }
}

class LevelBadge extends React.Component {
  render() {
    return (
      <div className="level-badge-container">
        <div className="level-badge">
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%"}}>
            <h1>Tu nivel es hoja</h1>
            <p>por que vas con el flujo del viento</p>
          </div>
          <img className="icon icon-leaf-badge"></img>
          
        </div>
      </div>
    );
  }
}

export { EnrolledCourses };
