import React from "react";
import { sleep } from "../../App";
import "./style.css";
import TherapyDetail from "../TherapyDetail";
import { getServices } from "../../hooks/services";
import { TherapistList } from "../../components/Therapists";
import { getGlobaltherapists, getTherapists } from "../../hooks/therapists";
import { LoadingComp } from "../Appointments";
import { Carousel } from "../../components/Carousel";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;

    this.state = {
      services: null,
      index: 0,
      therapist: null,
      therapists: null,
    };
  }

  async getTherapists() {
    let therapists = await getGlobaltherapists();
    // // console.log(therapists);

    if (!therapists || therapists.length <= 0) {
      return;
    }

    this.setState(() => ({
      therapist: therapists[0]._id,
      therapists: therapists,
    }));
  }

  async setTherapist(therapist) {
    let therapists = await getGlobaltherapists();

    this.setState((p) => {
      return { therapist: therapist, services: null, therapists: therapists };
    });
  }

  async loadServices() {
    // // console.log("load services")
    // // console.log(this.state.therapist);
    let [services, accData] = await getServices(this.state.therapist);

    let publicServices = [];

    services.forEach((service) => {
      if (service.public) {
        publicServices.push(service);
      }
    });

    // console.log(services);

    // // console.log("services");
    // // console.log(services);
    this.setState(() => ({ services: publicServices }));
  }

  async scrollToService(index) {
    // // console.log(index);
    let service = this.state.services[index];

    this.setState(() => ({ index: index }));
    await sleep(200);
    let selector = `div[id="${service._id}"]`;
    // // console.log(selector);
    let el = document.querySelector(selector);
    el.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  render() {
    let servicesView;

    if (!this.state.services && this.state.therapist) {
      this.loadServices();
      return (
        <>
          <TherapistList root={this}></TherapistList>
          {LoadingComp}
        </>
      );
    } else if (!this.state.services && !this.state.therapist) {
      return (
        <>
          <TherapistList root={this}></TherapistList>
          {LoadingComp}
        </>
      );
    } else if (this.state.services && this.state.therapists) {
      // // console.log("services and therapist found");
      servicesView = this.state.services.map((service, index) => {
        let therapist = this.state.therapists.filter(
          (ther) => ther._id == this.state.therapist
        );

        if (therapist && therapist.length > 0) {
          therapist = therapist[0];
        }
        return (
          <div
            key={service._id}
            id={service._id}
            className="service-card animated-intro"
            onTouchEnd={(e) => this.setState(() => ({ index: index }))}
          >
            <div className="card-header">
              <img className="cover" src={service.image} />
              <h1>{service.name}</h1>
              <img
                className="icon icon-share"
                onClick={async () => {
                  await navigator.share({
                    title: `${service.name}`,
                    text: service.name,
                    url: `${location.protocol}//${location.host}/?section=services&id=${service._id}`,
                  });
                }}
              ></img>
            </div>

            <div className="card-content">
              <p>
                {service.description.split("\n").map((paragraph) => {
                  return (
                    <>
                      {paragraph}
                      <br></br>
                      <br></br>
                    </>
                  );
                })}
              </p>
              <h3
                onClick={() =>
                  this.root.setComponent(TherapyDetail, null, {
                    ...service,
                    clientInfo: therapist,
                  })
                }
              >
                Agendar
              </h3>
            </div>
          </div>
        );
      });
    }
    return (
      <>
        <TherapistList root={this}></TherapistList>
        <div className="services">
          <p>Elige el espacio para ti</p>
          <div className="services-selector">{servicesView}</div>
        </div>
      </>
    );
  }
}

export { Services };
