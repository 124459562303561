import React from "react";
import "./style.css";
import { newUUID, sleep } from "../../dateutils/names";
import tarotMap from "./tarot_map_spanish.json";
import tarotMeanings from "./tarot_spanish.json";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

let tarotQuestions = [
  "Piensa en la situacion que quieres consultar",
  "Cual es el pasado que influencia el presente?",
  "Cual es la condicion para el momento?",
  "El resultado Final",
];

let historyquestions = [
  "Piensa en la historia que quieres consultar",
  "Que energia representa el problema?",
  "Cual seria el consejo?",
];

let bodyMind = [
  "Que estado representa tu cuerpo?",
  "Que estado representa tu mente?",
  "Que estado representa tu espiritu?",
];

let tarotCategories = [
  {
    type: "Unica pregunta",
    tarot: ["Piensa en (1) una sola pregunta"],
  },
  {
    type: "Cuerpo Mente y Espiritu",
    tarot: bodyMind,
  },
  {
    type: "Historia, problema y consejo",
    tarot: historyquestions,
  },
  {
    type: "Pasado, presente y resultado",
    tarot: tarotQuestions,
  },

  // To add:
  // Tarot del Zodiaco
  // Tirada de Luna nueva
  //
];

class AddPromo extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.state = {};
  }
  render() {
    return (
      <div
        className="ad-promo"
        onClick={() => {
          if (this.data && this.data.callback) {
            this.data.callback();
          }
        }}
      >
        <img></img>
        <div>
          <h1>Descubre</h1>
          <p>Cursos de Tarot</p>
        </div>
      </div>
    );
  }
}

class Tarot extends React.Component {
  constructor(props) {
    super(props);
    this.tarot = bodyMind;
    this.tarotCards = tarotMap;
    this.tarotMeanings = tarotMeanings;
    this.state = {
      categories: tarotCategories,
      currentCategory: 0,
      update: false,
      direction: null,
      reveal: false,
      selectedCard: null,
      cards: [],
      currentCard: 0,
      downloading: false,
    };
  }

  async update() {
    await sleep(200);
    this.setState(() => ({ update: true }));
  }

  async reveal() {
    let randomIndex = getRandomInt(this.tarotCards.length - 1);
    let randomCard = this.tarotCards[randomIndex];

    // check if card is not used already

    let breakRandom = false;

    this.state.cards.forEach((card) => {
      if (card.name == randomCard.name) {
        breakRandom = true;
        this.reveal();
      }
    });

    if (breakRandom) {
      return;
    }

    let images = require.context("../../images/cards", true);
    console.log(images);
    let image = images(`./${randomCard.img}`);

    randomCard["image"] = image;
    let imageDataReq = await fetch(image);
    let imageBlob = await imageDataReq.blob();

    let blobData = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(imageBlob);
    });

    randomCard["imageData"] = blobData; // window.URL.createObjectURL(imageBlob);
    randomCard["reversed"] = Math.random() < 0.5;

    console.log(image);

    // Get interpretation

    let interpretation = this.tarotMeanings.tarot.filter(
      (m) => m.name == randomCard.name
    );
    console.log(interpretation);

    if (interpretation && interpretation[0]) {
      randomCard["description"] = interpretation[0].description;
      randomCard["interpretation"] = interpretation[0].interpretation;
    }

    this.setState(() => ({
      selectedCard: randomCard,
      reveal: true,
      direction: null,
    }));
  }

  async right() {
    if (!this.state.reveal) {
      this.setState(() => ({ direction: "right", reveal: false }));
    }
  }

  left() {
    if (!this.state.reveal) {
      this.setState(() => ({ direction: "left" }));
    }
  }

  saveCard(card) {
    let cards = [...this.state.cards];
    cards.push(card);

    this.setState((p) => ({ direction: null, reveal: false, cards: cards }));
  }

  render() {
    if (!this.state.update) {
      this.update();
      return <div className="tarot-container"></div>;
    }

    let categories = (
      <div className="tarot-categories">
        {this.state.categories.map((category, index) => {
          return (
            <p
              className={index == this.state.currentCategory ? "selected" : ""}
              onClick={() => {
                this.setState(() => ({ currentCategory: index }));
              }}
            >
              {category.type}
            </p>
          );
        })}
      </div>
    );

    // Render para la tirada completa
    if (
      this.state.cards.length ==
      this.state.categories[this.state.currentCategory].tarot.length
    ) {
      let documentIndex = "";
      this.state.cards.map((card) => {
        documentIndex += card.number;
      });
      return (
        <div className="tarot-container animated-intro">
          <h1>Tarot</h1>
          <p>Tu tirada esta completa</p>
          <div className="selected-cards animated-intro">
            {this.state.cards.map((card, index) => {
              return (
                <div
                  className={
                    "card-mini-info " +
                    (this.state.currentCard == index ? "selected" : "")
                  }
                  onClick={() => this.setState(() => ({ currentCard: index }))}
                >
                  <img
                    style={{
                      backgroundImage: `url(${card.image})`,
                      transform: `rotate(${card.reversed ? "180deg" : "0deg"})`,
                    }}
                  ></img>
                  <p>
                    {card.name} {card.number}
                  </p>
                </div>
              );
            })}
          </div>

          <TarotCardInfo
            key={newUUID()}
            data={this.state.cards[this.state.currentCard]}
            tarot={
              this.state.categories[this.state.currentCategory].tarot[
                this.state.currentCard
              ]
            }
          ></TarotCardInfo>

          <AddPromo
            root={this}
            data={{
              callback: () => {
                window.open("/?section=courses&filter=tarot", "_self");
              },
            }}
          ></AddPromo>

          <button
            onClick={async () => {
              this.setState(() => ({ downloading: true }));
              let request = await fetch("/api/v1/pdf", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  cards: this.state.cards,
                  tarot:
                    this.state.categories[this.state.currentCategory].tarot,
                  type: this.state.categories[this.state.currentCategory].type,
                }),
              });
              if (request.status == 200) {
                let data = await request.blob();

                let dataUrl = window.URL.createObjectURL(data);
                let link = document.createElement("a");
                link.href = dataUrl;
                link.download = `tarot-${documentIndex}.pdf`;
                link.click();

                link.remove();
                this.setState(() => ({ downloading: false }));
              }
            }}
            className={this.state.downloading ? "loading-btn" : "extra-option"}
          >
            {this.state.downloading ? "Descargando PDF..." : "Descargar PDF"}
          </button>
          <br></br>
          <button
            onClick={() => {
              this.setState(() => ({ cards: [], currentCard: 0 }));
            }}
          >
            Reiniciar
          </button>
        </div>
      );
    }

    let message = "";

    return (
      <div className="tarot-container animated-intro">
        <h1>Tarot</h1>

        {this.state.cards.length == 0 && !this.state.reveal ? categories : ""}
        <br></br>

        {
          <p className="tarot-question animated-intro" key={newUUID()}>
            {
              this.state.categories[this.state.currentCategory].tarot[
                this.state.cards.length
              ]
            }
          </p>
        }
        <br></br>

        <img className="icon icon-tarot-select"></img>

        <div className="cards-container" key={newUUID()}>
          {this.state.direction && this.state.direction == "left" && (
            <>
              <div className="card-row" key={newUUID()}>
                <div
                  className="tarot-card btl"
                  onClick={() => this.left()}
                ></div>

                <div
                  className="tarot-card ltb stb"
                  onClick={() => this.reveal()}
                ></div>

                <div
                  className="tarot-card str"
                  onClick={() => this.right()}
                ></div>
              </div>
           
            </>
          )}

          {this.state.direction && this.state.direction == "right" && (
            <>
              <div className="card-row" key={newUUID()}>
                <div
                  className="tarot-card stl"
                  onClick={() => this.left()}
                ></div>
                
                <div
                  className="tarot-card rtb stb"
                  onClick={() => this.reveal()}
                ></div>

                <div
                  className="tarot-card btr"
                  onClick={() => this.right()}
                ></div>
              </div>
            </>
          )}

          {!this.state.direction && (
            <>
              <div className="card-row" key={newUUID()}>
                <div className={"tarot-card stl"} onClick={() => this.left()}></div>

                {this.state.reveal && (
                  <>
                    <div className="tarot-card stb flip"></div>
                    <img
                      className={
                        "tarot-card front-side " +
                        (this.state.selectedCard.reversed ? "reversed" : "")
                      }
                      style={{
                        backgroundImage: `url(${this.state.selectedCard.image})`,
                      }}
                      //   src="/api/v1/images?id=43639c33bd334e9098bffc500241152f.jpg"
                    ></img>
                  </>
                )}
                {!this.state.reveal && (
                  <div
                    className="tarot-card stb"
                    onClick={() => this.reveal()}
                  ></div>
                )}

                <div className={"tarot-card str"} onClick={() => this.right()}></div>
              </div>
              {/* <div className="card-row overlap"></div> */}
            </>
          )}
        </div>

        {this.state.reveal && (
          <button
            onClick={() => {
              if (this.state.reveal) {
                this.saveCard(this.state.selectedCard);
              } else {
                this.reveal();
              }
            }}
          >
            {this.state.reveal
              ? this.state.cards.length == tarotQuestions.length - 1
                ? "Ver resultados"
                : "Continuar tirada"
              : "Revelar"}
          </button>
        )}

        {this.state.reveal && this.state.selectedCard && (
          <TarotCardInfo data={this.state.selectedCard}></TarotCardInfo>
        )}

        {this.state.cards.length > 0 && (
          <div className="selected-cards animated-intro">
            {this.state.cards.map((card) => {
              return (
                <div className="card-mini-info">
                  <img
                    style={{
                      backgroundImage: `url(${card.image})`,
                      transform: `rotate(${card.reversed ? "180deg" : "0deg"})`,
                    }}
                  ></img>
                  <p>
                    {card.name} {card.number}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        <AddPromo
          root={this}
          data={{
            callback: () => {
              window.open("/?section=courses&filter=tarot", "_self");
            },
          }}
        ></AddPromo>
      </div>
    );
  }
}

class TarotCardInfo extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.tarot = props.tarot;
    this.state = {
      tab: 1,
    };
  }

  render() {
    return (
      <div className="tarot-card-info animated-intro">
        {this.tarot && <p className="tarot-question">{this.tarot}</p>}
        <h1>
          {this.data.name} | {this.data.number} {this.data.reversed ? "( Inv )": ""}
        </h1>
        <p>{this.data.arcana}</p>
        {this.data.interpretation && (
          <>
            <div className="card-info-select">
              <p
                className={this.state.tab == 0 && "selected"}
                onClick={() => this.setState(() => ({ tab: 0 }))}
              >
                Descripcion
              </p>
              <p
                className={this.state.tab == 1 && "selected"}
                onClick={() => this.setState(() => ({ tab: 1 }))}
              >
                Interpretacion
              </p>
            </div>
            <p className="interpretation animated-intro" key={newUUID()}>
              {this.state.tab == 0
                ? this.data.description
                : this.data.interpretation}
            </p>
          </>
        )}
      </div>
    );
  }
}

class TarotPage extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.state = {};
  }

  render() {
    <div className="tarot-page">
      <Tarot></Tarot>
    </div>;
  }
}

export { Tarot, TarotPage };
