import React from "react";

class EmailInput extends React.Component {
  constructor(props) {
    super(props);
    this.message = props.message;
    this.callback = props.callback;
    this.state = {
      email: null,
    };
  }

  async runCallback() {
    await this.updateEmail();
    this.callback();
  }

  async updateEmail() {
    const resp = await fetch("/api/v1/users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.email,
      }),
    });
  }

  render() {
    return (
      <div className="animated-intro email-input">
        <h1>{this.message}</h1>
        <input
          onChange={(e) => this.setState(() => ({ email: e.target.value }))}
        ></input>
        <button onClick={() => this.runCallback()}>Guardar</button>
      </div>
    );
  }
}

export { EmailInput };
