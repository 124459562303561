import React from "react";
import {
  deleteService,
  getServices,
  saveService,
} from "../../../hooks/services";
import { sleep } from "../../../App";
import { saveImage } from "../../../hooks/images";
import { DeleteAlert } from "../Courses/ModuleEditor/Blocks/delete";
import "./style.css";
import { formatter, newUUID } from "../../../dateutils/names";
import { ChartBar } from "../../../components/Charts";

class ServiceManager extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.userInfo = props.userInfo;
    this.state = {
      services: null,
      expanded: false,
      accData: null,
    };
  }

  async getServices() {
    if (this.userInfo) {
      let [services, accData] = await getServices(
        this.userInfo && this.userInfo._id
      );
      if (!services) {
        services = [];
      }
      this.setState(() => ({ services: services, accData: accData }));
    } else {
      await sleep(1000);
      this.setState(() => ({ services: null }));
    }
  }

  async toggle() {
    if (!this.state.services) {
      await this.getServices();
      await sleep(100);
    }
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  async createNewService() {
    let serviceId = await saveService({
      name: "Servicio",
      description: "description",
    });
    if (serviceId) {
      await sleep(200);
      this.root.renderComponent(ServiceCreator, {
        _id: serviceId,
        id: serviceId,
        name: "Servicio",
        description: "description",
      });
    }
  }

  updateService(service) {
    this.root.renderComponent(ServiceCreator, service);
  }

  render() {
    // if (!this.state.services) {
    //     this.getServices();
    // }

    let servicesView;

    if (this.state.services) {
      servicesView = this.state.services.map((service) => {
        return (
          <div
            className="admin-service-detail"
            onClick={() => this.updateService(service)}
          >
            <div>
              {service.image && (
                <img className="icon" src={service.image}></img>
              )}
            </div>
            <div>
              <h1>{service.name}</h1>

              {/* <p className="description">{service.description}</p> */}
              <p>{service._id}</p>
              <p>
                <span style={{ fontWeight: "bold" }}>Planes: </span>
                {service.plans.length}
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>Duracion: </span>
                {service.time} minutos
              </p>
            </div>
          </div>
        );
      });
    }

    let chartStyle;

    // if (this.state.accData) {
    //   chartStyle = {
    //     display: "grid",
    //     gridTemplateColumns: `repeat(${
    //       this.state.accData.length
    //     }, calc(${Math.ceil(100 / this.state.accData.length)}% - 4px))`,
    //   };
    //   // console.log(chartStyle);
    // }
    return (
      <div
        className={`service-manager ${this.state.expanded ? "expanded" : ""}`}
      >
        <div className="service-main-actions" onClick={() => this.toggle()}>
          {this.state.expanded ? (
            <>
              <h1>Servicios</h1>
              <div>
                <button onClick={() => this.createNewService()}>Agregar</button>
                <img className="icon icon-collapse-card"></img>
              </div>
            </>
          ) : (
            <>
              <h1>Servicios</h1>
            </>
          )}
        </div>
        {this.state.expanded ? servicesView : null}
        {this.state.expanded && this.state.accData ? (
          <ChartBar data={this.state.accData} label={"Servicios agendados en el mes"}></ChartBar>
          // <div className="chart-bars" key={newUUID()} style={chartStyle}>
          //   <h1>Meta de citas: {this.state.accData.max}</h1>
          //   <p>Servicios agendados en el mes</p>
          //   {this.state.accData.metrics.map((metric) => {
          //     return (
          //       <div className="chart-bar">
          //         <p>
          //           {metric.name} ({metric.units})
          //         </p>
          //         <div className="bar">
          //           <p style={{ width: `${metric.percent}%` }}></p>
          //         </div>
          //       </div>
          //     );
          //   })}
          // </div>
        ) : null}
      </div>
    );
  }
}



class ServiceCreator extends React.Component {
  constructor(props) {
    super(props);

    this.root = props.root;
    this.data = props.data;
    this.owner = props.owner;
    this.plan_template = {
      price: null,
      sessions: null,
      usd_price: null,
    };

    let data = props.data || {};

    this.state = {
      id: data._id || null,
      name: data.name || null,
      public: data.public || null,
      description: data.description || null,
      plans: data.plans || [],
      tempPlan: null,
      time: data.time || 60,
      imageData: data.image || null,
      planEditor: false,
      index: null,
      alert: null,
    };
  }

  readImage(e) {
    let component = this;
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = (ev) => {
      let contents = ev.target.result;
      component.setState(() => ({ imageData: contents }));
      // component.setState(() => ({ tones: tones }));
    };
    reader.readAsDataURL(file);
  }

  uploadCover() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.png,.jpeg";
    input.onchange = async (event) => {
      this.setState(() => ({ uploading: true }));
      let imageURL = await saveImage(event.target.files[0], this.state.src);
      component.setState(() => ({ imageData: imageURL, uploading: false }));
      await sleep(1000);
      this.saveService();
    };
    input.click();
  }

  updatetempPlan(field, value) {
    this.setState((p) => {
      let tempPlan = p.tempPlan;
      if (!tempPlan) {
        tempPlan = {};
      }
      tempPlan[field] = value;
      return {
        tempPlan: tempPlan,
      };
    });
  }

  async saveService() {
    let serviceTemplate = {
      name: this.state.name,
      description: this.state.description,
      time: this.state.time,
      plans: this.state.plans,
      image: this.state.imageData,
      public: this.state.public,
      id: this.data ? this.data._id : this.state.id,
      owner: this.owner,
    };

    let id = await saveService(serviceTemplate);
    if (!this.state.id) {
      this.setState(() => ({ id: id }));
    }
  }

  close() {
    this.root.renderComponent(null, null);
  }

  renderPlanEditor() {
    // // console.log("render plan editor");
    let planData = Object.assign({}, this.state.tempPlan);
    return (
      <div className="plan-editor-container">
        <div className="service-main-actions plan-editor">
          <h1>Editor de plan</h1>
          <button
            onClick={() =>
              this.setState((p) => {
                let plans = p.plans;
                if (p.index) {
                  plans[p.index - 1] = planData;
                } else {
                  plans.push(planData);
                }
                return {
                  planEditor: false,
                  plans: plans,
                  tempPlan: null,
                  index: null,
                };
              })
            }
          >
            Guardar
          </button>
        </div>
        <label>Sesiones</label>
        <select
          onChange={(e) => this.updatetempPlan("sessions", e.target.value)}
          value={planData.sessions || 1}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={4}>4</option>
          <option value={6}>6</option>
        </select>
        <br></br>
        <label>Precio en COP</label>
        <input
          type="number"
          onChange={(e) => this.updatetempPlan("price", e.target.value)}
          value={planData.price || undefined}
        ></input>
        <p>Precio con tarifa</p>
        <p>{formatter.format(planData.price, true)}</p>
        <br></br>
        {/* <label>Precio en USD</label>
        <input
          type="number"
          onChange={(e) => this.updatetempPlan("usd_price", e.target.value)}
          value={planData.usd_price || undefined}
        ></input>
        <br></br>
        <label>Precio en EUR</label>
        <input
          type="number"
          onChange={(e) => this.updatetempPlan("eur_price", e.target.value)}
          value={planData.eur_price || undefined}
        ></input> */}
        <button
          onClick={() =>
            this.setState(() => ({ planEditor: false, tempPlan: null }))
          }
        >
          Cerrar
        </button>
        {/* <p>Los precios en otras divisas se calculan en base a la tasa de cambio vigente al momento de agendar la cita.</p> */}
      </div>
    );
  }

  renderPlanCard(index, plan) {
    return (
      <div className="creator-plan-card">
        <div>
          <h1>Sesiones: {plan.sessions}</h1>
          <p>
            Precio en COP:{" "}
            <span style={{ fontWeight: "bold" }}>{formatter.format(plan.price)}</span>
            
          </p>
          <p>
          <span style={{ fontWeight: "bold" }}>con tarifa {formatter.format(plan.price, true)}</span>
          </p>
          {/* <p>
            Precio en USD:{" "}
            <span style={{ fontWeight: "bold" }}>${plan.usd_price}</span>
          </p>
          <p>
            Precio en EUR:{" "}
            <span style={{ fontWeight: "bold" }}>${plan.eur_price}</span>
          </p> */}
        </div>
        <div
          style={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
         
          <img
            className="icon icon-delete"
            onClick={() => this.deletePlan(index)}
          ></img>
           <img
            className="icon icon-edit"
            onClick={() =>
              this.setState(() => ({ tempPlan: plan, index: index }))
            }
          ></img>
        </div>
      </div>
    );
  }

  async deleteService() {
    const root = this.root;
    const id = this.data._id;
    let callback = async () => {
      await deleteService(id);
      root.renderComponent(null, null);
    };
    this.setState(() => ({
      alert: DeleteAlert,
      alertData: {
        title: "Borrar servicio",
        description: "Esta accion no se puede deshacer, quieres continuar?",
        callback: callback,
      },
    }));
  }

  deletePlan(planIndex) {
    let comp = this;

    let callback = async () => {
      comp.setState((p) => {
        let plans = p.plans;
        plans.splice(planIndex, 1);
        if (plans.length == 1) {
          plans = [];
        }
        return {
          plans: plans,
          alert: null,
        };
      });
    };
    this.setState(() => ({
      alert: DeleteAlert,
      alertData: {
        title: "Borrar Plan",
        description: "Esta accion no se puede deshacer, quieres continuar?",
        callback: callback,
      },
    }));
  }

  async closealert(now) {
    if (!now) {
      await sleep(10000);
    }
    this.setState(() => ({ alert: null }));
  }

  render() {
    let AlertComponent;
    if (this.state.alert) {
      AlertComponent = this.state.alert;
    }
    return (
      <div className="service-creator">
        {AlertComponent && (
          <AlertComponent
            root={this}
            data={this.state.alertData}
          ></AlertComponent>
        )}

        {(this.state.planEditor || this.state.tempPlan) &&
          this.renderPlanEditor()}

        <div className="service-main-actions page-editor">
          <img
            className="icon icon-back"
            onClick={() => {
              this.saveService();
              this.close();
            }}
          ></img>
          <h1>Editor de Servicio</h1>
          <button
            style={{ backgroundColor: "var(--second)" }}
            onClick={() => this.shareCourse()}
          >
            Compartir
          </button>
        </div>

        <div className="editor-content">
          {this.state.imageData ? (
            <div
              className={
                "service-main-actions cover " +
                (this.state.uploading ? "" : "active")
              }
              onClick={() => this.uploadCover()}
            >
              {this.state.uploading ? (
                <img className="icon icon-load"></img>
              ) : (
                <img src={this.state.imageData}></img>
              )}
              <p>
                {this.state.uploading
                  ? "Subiendo Portada"
                  : "Cambiar la portada"}
              </p>
            </div>
          ) : (
            <div
              className="service-main-actions cover"
              onClick={() => this.uploadCover()}
            >
              {this.state.uploading ? (
                <img className="icon icon-load"></img>
              ) : (
                <img className="icon icon-add-cover"></img>
              )}
              <p>
                {this.state.uploading ? "Subiendo Portada" : "Sube una portada"}
              </p>
            </div>
          )}

          {!this.state.public && (
            <div className="publish-header">
              <p>Sin publicar</p>
              <button
                className="public"
                onClick={() => this.setState(() => ({ public: true }))}
              >
                Publicar
              </button>
            </div>
          )}

          {this.state.public && (
            <div className="publish-header">
              <p>Publicado</p>
              <button onClick={() => this.setState(() => ({ public: false }))}>
                Regresar a Borrador
              </button>
            </div>
          )}

          <label>Nombre</label>
          <input
            value={this.state.name || ""}
            placeholder="..."
            onChange={(e) => this.setState(() => ({ name: e.target.value }))}
          ></input>

          <br></br>

          <label>Descripcion</label>
          <textarea
            value={this.state.description || ""}
            onChange={(e) =>
              this.setState(() => ({ description: e.target.value }))
            }
          ></textarea>
          <br></br>
          <label>Duracion</label>
          <select
            defaultValue={this.state.time || 60}
            onChange={(e) => this.setState(() => ({ time: e.target.value }))}
          >
            <option value={60}>60 Minutos</option>
            <option value={120}>120 Minutos</option>
          </select>
          <br></br>
          <div>
            <div className="service-main-actions plan-creator">
              <h1>Planes</h1>
              <button
                onClick={() =>
                  this.setState(() => ({ planEditor: true, tempPlan: {} }))
                }
              >
                Agregar
              </button>
            </div>
            <div>
              {this.state.plans.map((plan, index) => {
                return this.renderPlanCard(index + 1, plan);
              })}
            </div>
          </div>
          <div className="nav-ops">
            {this.data && (
              <p className="accent-option" onClick={() => this.deleteService()}>Borrar Servicio</p>
            )}
            <p onClick={() => this.root.renderComponent(null, null)}>Salir</p>
          </div>
        </div>
      </div>
    );
  }
}

export { ServiceCreator, ServiceManager };
