import React from "react";
import "./style.css";
import { Workshops } from "..";
import { Checkout } from "../../../components/Checkout";
import { getClientInfo } from "../../../hooks/clientInfo";
import { dayNames, formatter, monthNames } from "../../../dateutils/names";
import { getGlobalClientInfo } from "../../../hooks/clientInfo";
import { saveImage } from "../../../hooks/images";
import { PostManager } from "../../../components/PostManager";

let image = "/api/v1/images?id=9cfe65a90ea8476a94801521fae5dd23.jpg";
let posts = [
  {
    caption:
      "Nos estamos preparando para recibir a todos nuestrosparticipantes!.",
    image: image,
    user: {
      name: "Borigui",
      image: image,
    },
  },
];

class PurchasedWorkshopDetails extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = {};
  }

  render() {
    let detail = this.data;

    // if (!this.state.posts) {
    //   this.loadPosts();
    // }

    // let addPost;
    // if (this.state.addPost) {
    //   addPost = (
    //     <div className="add-post">
    //       <div className="image-input" onClick={() => this.tempSaveimageFile()}>
    //         <img
    //           src={
    //             this.state.postImage &&
    //             URL.createObjectURL(this.state.postImage)
    //           }
    //         ></img>
    //         <p>Subir imagen</p>
    //       </div>
    //       <textarea
    //         placeholder="Escribe aqui tu comentario"
    //         onChange={(e) =>
    //           this.setState(() => ({ postComment: e.target.value }))
    //         }
    //       ></textarea>
    //       <button onClick={() => this.uploadPost()}>enviar</button>
    //     </div>
    //   );
    // } else {
    //   addPost = (
    //     <div
    //       className="toggle-add"
    //       onClick={() => this.setState(() => ({ addPost: true }))}
    //     >
    //       <img className="icon"></img>
    //       <p>Subir Post</p>
    //     </div>
    //   );
    // }

    let time = new Date(detail.date.year, detail.date.month, detail.date.day);
    let date = `${dayNames[time.getDay()]}, ${time.getDate()} ${
      monthNames[time.getMonth()]
    } ${time.getFullYear()}`;

    return (
      <div className="workshop-detail granted">
        <div className="workshop-header">
          <img src={detail.image}></img>
          <h1>{date}</h1>
        </div>

        <div>
          <h1>{detail.name}</h1>
          <p>
            Duración <span>{detail.time / 60} horas</span>{" "}
          </p>
          <p>
            Facilitado por <span>{detail.owner_name}</span>{" "}
          </p>
          <p>
            Lugar <span>{detail.location}</span>{" "}
          </p>
        </div>
        <div>
          <h1>Descripcion</h1>
          <p>{detail.description}</p>
        </div>
        <br></br>
        <PostManager data={this.data} entity="workshops"></PostManager>
        {/* <div>
          {this.state.posts &&
            this.state.posts.map((post) => {
              return (
                <div className="post">
                  <div className="user">
                    <img src={post.user && post.user.image}></img>
                    <p>{post.user && post.user.name}</p>
                  </div>
                  <img src={post.image}></img>
                  <div className="interactions">
                    <div className="field">
                      <img className="icon icon-fav-product"></img>
                      <p>12</p>
                    </div>
                  </div>
                  <p>{post.caption}</p>
                  <p onClick={() => this.deletePost(post._id)}>Delete post</p>
                </div>
              );
            })}
          {addPost}
        </div> */}
        <div className="nav-ops">
          <p onClick={() => this.root.setComponent(Workshops)}>Volver</p>
        </div>
      </div>
    );
  }
}

class WorkshopDetail extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    // console.log(this.data);
    this.state = {
      posts: null,
    };
  }

  async purchase() {
    // let result = await getClientInfo();
    // // console.log(result);
    // if (!result || Object.keys(result).length === 0) {
    //   let purchaseInvoiceResult = await generatePurchase("workshops", this.data._id);
    // }
    // await generatePurchase("workshops", this.data._id);
    this.root.setComponent(Checkout, null, {
      entityType: "workshops",
      entityId: this.data._id,
    });
  }

  async checkPosts() {
    const clientInfo = await getGlobalClientInfo();
    if (clientInfo) {
      console.log("check for posts");
      console.log(clientInfo);
    }
  }

  render() {
    let detail = this.data;
    if (!this.state.posts) {
      this.checkPosts();
    }
    if (this.data.purchase) {
      return (
        <PurchasedWorkshopDetails
          root={this}
          data={this.data}
        ></PurchasedWorkshopDetails>
      );
    }

    let time = new Date(detail.date.year, detail.date.month, detail.date.day);
    let date = `${dayNames[time.getDay()]}, ${time.getDate()} ${
      monthNames[time.getMonth()]
    } ${time.getFullYear()}`;

    return (
      <div className="workshop-detail animated-intro">
        <div className="workshop-header">
          <img className="workshop-cover" src={detail.image}></img>
          <pre>{detail.name + "\n" + date}</pre>
          <img
            className="icon icon-share"
            onClick={async () => {
              if (navigator.share) {
                await navigator.share({
                  title: `Taller ${detail.name}`,
                  text: detail.description,
                  url: `${location.protocol}//${location.host}/?section=workshops&id=${detail._id}`,
                });
              } else {
                window.open(`${location.protocol}//${location.host}/?section=workshops&id=${detail._id}`, "_self");
              }
            }}
          ></img>
        </div>

        <div>
          <br></br>
          <p>
            Duración <span>{detail.time / 60} horas</span>{" "}
          </p>
          <p>
            Facilitado por <span>{detail.owner_name}</span>{" "}
          </p>
          <p>
            Lugar <span>{detail.location}</span>{" "}
          </p>
        </div>
        <div>
          <h1>Descripcion</h1>
          <p>{detail.description}</p>
          <p className="price">
            Valor del cupo:{" "}
            <span>{formatter.format(detail.plans[0].price, true)}</span>
          </p>
        </div>

        <div className="nav-ops">
          <p className="accent-option" onClick={() => this.purchase()}>
            Reservar Cupo
          </p>
        </div>

        <PostManager data={this.data} entity="workshops"></PostManager>

        <div className="nav-ops">
          <p onClick={() => this.root.setComponent(Workshops)}>Volver</p>
        </div>
      </div>
    );
  }
}

export { WorkshopDetail, PostManager };
