import React from "react";
import { newUUID } from "../../dateutils/names";
import './style.css';

class ChartBar extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.label = props.label;
    this.description = props.description;
  }
  render() {
    return (
      <div className="chart-bars" key={newUUID()}>
        {/* <h1>Meta de citas: {this.data.max}</h1> */}
        <p>{this.label}</p>
        {this.data && this.data.metrics
          ? this.data.metrics.map((metric) => {
              return (
                <div className="chart-bar">
                  <p>
                    {metric.name} ({metric.units})
                  </p>
                  <div className="bar">
                    <p style={{ width: `${metric.percent}%` }}></p>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    );
  }
}

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.label = props.label;
    this.description = props.description;
  }
  render() {
    return (
      <div className="progress-bars" key={newUUID()}>
        {/* <h1>Meta de citas: {this.data.max}</h1> */}
        {/* <p>{this.label}</p> */}
        {this.data && this.data.metrics
          ? this.data.metrics.map((metric) => {
              return (
                <div className="progress-bar">
                  <p>
                    {metric.name} {metric.percent}%
                  </p>
                  <div className="bar">
                    <p style={{ width: `${metric.percent}%` }}></p>
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    );
  }
}

export { ChartBar, ProgressBar };
