import React from "react";
import { saveImage } from "../../../../hooks/images";
import { deleteCourse, saveCourse } from "../../../../hooks/courses";
import { DeleteAlert } from "../ModuleEditor/Blocks/delete";
import { formatter, newUUID } from "../../../../dateutils/names";
import { sleep } from "../../../../dateutils/names";
import { ModuleEditor } from "../ModuleEditor";
import "./style.css";

class CourseCreator extends React.Component {
  constructor(props) {
    super(props);

    this.root = props.root;
    this.data = props.data;
    this.owner = props.owner;
    this.price_template = {
      price: null,
      sessions: null,
      usd_price: null,
    };

    let data = props.data || {};

    this.state = {
      id: data._id || null,
      name: data.name || null,
      description: data.description || null,
      tag: data.tag || null,
      plans: data.plans || [],
      tempprice: null,
      image: data.image || null,
      modules: data.modules || null,
      public: data.public || null,
      priceEditor: false,
      index: null,
      alert: null,
      editModule: false,
      certificate: data.certificate || null,
    };
  }

  readImage(e) {
    let component = this;
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = (ev) => {
      let contents = ev.target.result;
      component.setState(() => ({ icon: contents }));
      // component.setState(() => ({ tones: tones }));
    };
    reader.readAsDataURL(file);
  }

  uploadCover() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.png,.jpeg";
    input.onchange = async (event) => {
      this.setState(() => ({ uploading: true }));

      let imageURL = await saveImage(event.target.files[0], this.state.image);
      component.setState(() => ({ image: imageURL, uploading: false }));
      component.saveCourseSilent();
    };
    input.click();
  }

  uploadCertificate() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.png,.jpeg";
    input.onchange = async (event) => {
      this.setState(() => ({ uploading: true }));

      let imageURL = await saveImage(event.target.files[0], this.state.image);
      component.setState(() => ({
        certificate: { image: imageURL },
        uploading: false,
      }));
      component.saveCourseSilent();
    };
    input.click();
  }

  addModule() {
    this.setState((p) => {
      let modules = p.modules;
      if (!modules) {
        modules = [];
      }

      modules.push({
        title: "Modulo Sin titulo",
        description: "descripcion del modulo",
        tag: null,
      });
      return { modules: modules };
    });
  }

  updatetempprice(field, value) {
    this.setState((p) => {
      let tempprice = p.tempprice;
      if (!tempprice) {
        tempprice = {};
      }
      tempprice[field] = value;
      return {
        tempprice: tempprice,
      };
    });
  }

  async saveCourseSilent() {
    let courseTemplate = {
      name: this.state.name,
      description: this.state.description,
      plans: this.state.plans,
      tag: this.state.tag,
      image: this.state.image,
      public: this.state.public,
      id: this.data ? this.data._id : this.state.id,
      modules: this.state.modules,
      certificate: this.state.certificate,
    };
    let id = await saveCourse(courseTemplate);
    if (!this.state.id) {
      this.setState(() => ({ id: id }));
    }
  }

  async saveCourse() {
    await this.saveCourseSilent();
  }

  close() {
    this.root.renderComponent(null, null);
  }

  renderpriceEditor() {
    // // // console.log("render price editor");
    let priceData = Object.assign({}, this.state.tempprice);
    return (
      <div className="price-editor-container">
        <div className="service-main-actions price-editor">
          <h1>Editor de price</h1>
          <button
            onClick={() =>
              this.setState((p) => {
                let plans = p.plans;
                if (p.index) {
                  plans[p.index - 1] = priceData;
                } else {
                  plans.push(priceData);
                }
                return {
                  priceEditor: false,
                  plans: plans,
                  tempprice: null,
                  index: null,
                };
              })
            }
          >
            Guardar
          </button>
        </div>
        <br></br>
        <label>Precio en COP</label>
        <input
          type="number"
          onChange={(e) => this.updatetempprice("price", e.target.value)}
          value={priceData.price || undefined}
        ></input>
        <br></br>
        {/* 
          <label>Precio en USD</label>
          <input
            type="number"
            onChange={(e) => this.updatetempprice("usd_price", e.target.value)}
            value={priceData.usd_price || undefined}
          ></input>
          <br></br>
          <label>Precio en EUR</label>
          <input
            type="number"
            onChange={(e) => this.updatetempprice("eur_price", e.target.value)}
            value={priceData.eur_price || undefined}
          ></input> */}
        <button
          onClick={() =>
            this.setState(() => ({ priceEditor: false, tempprice: null }))
          }
        >
          Cerrar
        </button>
        {/* <p>Los precios en otras divisas se calculan en base a la tasa de cambio vigente al momento de agendar la cita.</p> */}
      </div>
    );
  }

  renderpriceCard(index, price) {
    return (
      <div className="creator-price-card">
        <div>
          <p>
            Precio{" "}
            <span style={{ fontWeight: "bold" }}>
              {formatter.format(price.price)}
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              con tarifa {formatter.format(price.price, true)}
            </span>
          </p>
          {/* <p>
              Precio en USD:{" "}
              <span style={{ fontWeight: "bold" }}>${price.usd_price}</span>
            </p>
            <p>
              Precio en EUR:{" "}
              <span style={{ fontWeight: "bold" }}>${price.eur_price}</span>
            </p> */}
        </div>
        <div
          style={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="icon icon-delete"
            onClick={() => this.deleteprice(index)}
          ></img>
          <img
            className="icon icon-edit"
            onClick={() =>
              this.setState(() => ({ tempprice: price, index: index }))
            }
          ></img>
        </div>
      </div>
    );
  }

  async deleteCourse() {
    const root = this.root;
    const id = this.data._id;
    let callback = async () => {
      if (this.state.modules) {
        this.state.modules.map((module) => {
          this.deleteVideosFromModule(module);
        });
      }
      await deleteCourse(id);
      root.renderComponent(null, null);
    };
    this.setState(() => ({
      alert: DeleteAlert,
      alertData: {
        title: "Borrar Curso " + this.state.name,
        description: "Esta accion no puede deshacerse, quieres continuar?",
        callback: callback,
      },
    }));
  }

  async deleteVideosFromModule(module) {
    module.pages.map(async (page) => {
      if (page.type == "video" && page.src) {
        let videoId = page.src.split("=")[1];
        let req = await fetch(`/api/v1/videos/${videoId}`, {
          method: "DELETE",
        });
      }
    });
  }

  async deleteModule(index) {
    this.setState(() => ({
      alert: DeleteAlert,
      alertData: {
        title: "Borrar Modulo " + this.state.name,
        description: "Esta accion no puede deshacerse, quieres continuar?",
        callback: async () => {
          await this.deleteVideosFromModule(this.state.modules[index]);
          this.setState(async (p) => {
            let modules = p.modules;
            modules.splice(index, 1);
            return { modules: modules };
          });
        },
      },
    }));
  }

  deleteprice(priceIndex) {
    let comp = this;

    let callback = async () => {
      comp.setState((p) => {
        let plans = p.plans;
        plans.splice(priceIndex, 1);
        if (plans.length == 1) {
          plans = [];
        }
        return {
          plans: plans,
          alert: null,
        };
      });
    };
    this.setState(() => ({
      alert: DeleteAlert,
      alertData: {
        title: "Deseas Eliminar este precio?",
        description: null,
        callback: callback,
      },
    }));
  }

  async closealert(now) {
    if (!now) {
      await sleep(10000);
    }
    this.setState(() => ({ alert: null }));
  }

  editModule(index) {
    this.setState(() => ({ editModule: true, moduleIndex: index }));
  }

  render() {
    if (this.state.editModule) {
      return (
        <ModuleEditor
          key={newUUID()}
          root={this}
          data={this.state}
          index={this.state.moduleIndex}
        ></ModuleEditor>
      );
    }
    let AlertComponent = this.state.alert;
    return (
      <div className="course-creator">
        {AlertComponent && (
          <AlertComponent
            root={this}
            data={this.state.alertData}
          ></AlertComponent>
        )}
        {(this.state.priceEditor || this.state.tempprice) &&
          this.renderpriceEditor()}
        <div className="service-main-actions page-editor">
          {/* <img
            className="icon icon-back"
            onClick={() => {
              this.saveCourseSilent();
              this.close();
            }}
          ></img> */}
          <h1>Editor de Cursos</h1>
          <button
            style={{ backgroundColor: "var(--second)" }}
            onClick={() => {
              this.saveCourseSilent();
              this.close();
            }}
          >
            Guardar
          </button>
        </div>
        <div className="editor-content">
          {this.state.image ? (
            <div
              className={
                "service-main-actions cover " +
                (this.state.uploading ? "" : "active")
              }
              onClick={() => this.uploadCover()}
            >
              {this.state.uploading ? (
                <img className="icon icon-load"></img>
              ) : (
                <img src={this.state.image}></img>
              )}
              <p>
                {this.state.uploading
                  ? "Subiendo Portada"
                  : "Cambiar la portada"}
              </p>
            </div>
          ) : (
            <div
              className="service-main-actions cover"
              onClick={() => this.uploadCover()}
            >
              {this.state.uploading ? (
                <img className="icon icon-load"></img>
              ) : (
                <img className="icon icon-add-cover"></img>
              )}
              <p>
                {this.state.uploading ? "Subiendo Portada" : "Sube una portada"}
              </p>
            </div>
          )}
          {!this.state.public && (
            <div className="publish-header">
              <p>Sin publicar</p>
              <button
                className="public"
                onClick={async () => {
                  this.setState(() => ({ public: true }));
                  await sleep(100);
                  this.saveCourseSilent();
                }}
              >
                Publicar
              </button>
            </div>
          )}

          {this.state.public && (
            <div className="publish-header">
              <p>Publicado</p>
              <button
                onClick={async () => {
                  this.setState(() => ({ public: false }));
                  await sleep(100);
                  this.saveCourseSilent();
                }}
              >
                Regresar a Borrador
              </button>
            </div>
          )}

          <label>Titulo del curso</label>
          <input
            defaultValue={this.state.name || ""}
            placeholder="..."
            onChange={(e) => this.setState(() => ({ name: e.target.value }))}
          ></input>

          <br></br>

          <label>Descripcion del curso</label>
          <textarea
            defaultValue={this.state.description || ""}
            onChange={(e) =>
              this.setState(() => ({ description: e.target.value }))
            }
          ></textarea>
          <br></br>
          <label>Etiqueta (categoria)</label>
          <select
            defaultValue={this.state.tag || "auto-conocimiento"}
            onChange={(e) => this.setState(() => ({ tag: e.target.value }))}
          >
            <option value="auto-conocimiento">Auto-conocimiento</option>
            <option value="pareja">Pareja</option>
            <option value="infancia">Infancia</option>
            <option value="etica">Etica</option>
            <option value="sexualidad">Sexualidad</option>
            <option value="tarot">Tarot</option>
          </select>
          <br></br>

          <div className="modules-container">
            <label>Modulos</label>
            {this.state.modules &&
              this.state.modules.map((module, index) => {
                return (
                  <div className="module-card">
                    <img src={module.icon}></img>
                    <div>
                      <h1>{module.title}</h1>
                      <p>{module.description}</p>
                    </div>
                    <img
                      className="icon icon-module-delete"
                      onClick={() => this.deleteModule(index)}
                    ></img>
                    <img
                      className="icon icon-settings-full"
                      onClick={() => this.editModule(index)}
                    ></img>
                    <img className="icon icon-grab-move"></img>
                  </div>
                );
              })}
            <button onClick={() => this.addModule()}>Agregar Modulo +</button>
          </div>

          <br></br>
          <div>
            <div className="service-main-actions price-creator">
              <h1>Precio</h1>
              {!this.state.plans ||
                (this.state.plans.length == 0 && (
                  <button
                    onClick={() =>
                      this.setState(() => ({
                        priceEditor: true,
                        tempprice: {},
                      }))
                    }
                  >
                    Agregar
                  </button>
                ))}
            </div>
            <div>
              {this.state.plans.map((price, index) => {
                return this.renderpriceCard(index + 1, price);
              })}
            </div>
          </div>

          <div className="certificate-input-container">
            <div
              className="certificate-input"
              onClick={() => this.uploadCertificate()}
            >
              {this.state.certificate && (
                <img
                  src={this.state.certificate.image}
                  className="input-cover"
                ></img>
              )}
              <img className="icon icon-upload"></img>
              <p>{!this.state.certificate ? "Subir certificado" : "Cambiar certificado"}</p>
            </div>
            <p>
              Sube la pantilla del certificado para este curso en formato PDF, o
              PNG
            </p>
          </div>

          <div className="course-options-container">
            {this.data && (
              <button
                className="delete-course"
                onClick={() => this.deleteCourse()}
              >
                Borrar Curso
              </button>
            )}
            <button>Vista Previa (beta)</button>
            <button>Ver lista de usuarios y tareas (beta)</button>
            <button>Administrar Cupones (beta)</button>
          </div>
        </div>
      </div>
    );
  }
}

export { CourseCreator };
