import "./style.css";
import React from "react";
import {
  getCourses,
  getAdminCourses,
  saveCourse,
  deleteCourse,
} from "../../../hooks/courses";
import { sleep } from "../../../App";
import { saveImage, deleteImage } from "../../../hooks/images";
import { saveAudio } from "../../../hooks/audios";
import { currentScheme } from "../../../colors";
import { getRandomUUID } from "../../../constants.js";
import { TherapistList } from "../../../components/Therapists/index.js";
import { newUUID } from "../../../dateutils/names.js";
import { CourseCreator } from "./CourseCreator/index.js";
import { ChartBar } from "../../../components/Charts/index.js";


class CourseManager extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.userInfo = props.userInfo;
    this.state = {
      courses: null,
      creatingCourse: false,
      expanded: false,
      accData: null,
    };
  }

  async getCourses() {
    if (this.userInfo) {
      let [courses, accData] = await getAdminCourses(
        this.userInfo && this.userInfo._id
      );
      if (!courses) {
        courses = [];
      }
      this.setState(() => ({ courses: courses, accData: accData }));
    } else {
      await sleep(1000);
      this.setState(() => ({ courses: null }));
    }
  }

  async createNewCourse() {
    let courseId = await saveCourse({
      name: "New Course",
      description: "course description",
    });
    if (courseId) {
      await sleep(200);
      this.root.renderComponent(CourseCreator, {
        _id: courseId,
        id: courseId,
        name: "New Course",
        description: "course description",
      });
    }
  }

  editCourse(course) {
    this.root.renderComponent(CourseCreator, course);
  }

  async toggle() {
    if (!this.state.courses) {
      await this.getCourses();
      await sleep(100);
    }
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  render() {
    let coursesView;

    if (this.state.courses) {
      coursesView = this.state.courses.map((course) => {
        return (
          <div
            className="admin-course-detail"
            onClick={() => this.editCourse(course)}
          >
            <div>
              {course.image && <img className="icon" src={course.image}></img>}
            </div>
            <div>
              <h1>{course.name}</h1>
              <p>{course.description}</p>
              <p className={course.public ? "card public" : "card draft"}>
                {course.public ? "Publicado" : "Borrador"}
              </p>
            </div>
          </div>
        );
      });
    }
    return (
      <div
        className={`course-manager ${this.state.expanded ? "expanded" : ""}`}
      >
        <div
          className="service-main-actions"
          onClick={(e) =>
            (e.target.tagName == "DIV" || e.target.tagName == "H1" || e.target.tagName == "IMG") &&
            this.toggle(e)
          }
        >
          {this.state.expanded ? (
            <>
              <h1>Cursos</h1>
              <div>
                <button
                  onClick={async (e) => {
                    // // // console.log(e.target.tagName);

                    if (
                      !this.state.creatingCourse &&
                      e.target.tagName == "BUTTON"
                    ) {
                      this.setState(() => ({ creatingCourse: true }));
                      await sleep(200);
                      this.createNewCourse();
                    }
                  }}
                >
                  Agregar
                </button>
                <img className="icon icon-collapse-card"></img>
              </div>
            </>
          ) : (
            <>
              <h1>Cursos</h1>
            </>
          )}
        </div>
        {this.state.expanded ? coursesView : null}
        {this.state.expanded && this.state.accData ? (
          <ChartBar data={this.state.accData} label={"Cursos Vendidos"}></ChartBar>
          // <div className="chart-bars" key={newUUID()}>
          //   <h1>Meta de citas: {this.state.accData.max}</h1>
          //   <p>Cursos Vendidos</p>
          //   {this.state.accData.metrics.map((metric) => {
          //     return (
          //       <div className="chart-bar">
          //         <p>
          //           {metric.name} ({metric.units})
          //         </p>
          //         <div className="bar">
          //           <p style={{ width: `${metric.percent}%` }}></p>
          //         </div>
          //       </div>
          //     );
          //   })}
          // </div>
        ) : null}
      </div>
    );
  }
}



export { CourseManager };
