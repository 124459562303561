import React from "react";
import "./styles.css";
import { dayNames, monthNames } from "../../Appointments";
import { Dates } from "..";
import {
  deleteAppointment,
  getSingleAppointment,
} from "../../../hooks/appointments";
import { APPO_STATUS_CODES } from "../constants";
import { sleep } from "../../../App";
import { colorPalletes, currentScheme } from "../../../colors";
import AppointmentsConfig from "../../Appointments";
import { COP, newUUID } from "../../../dateutils/names";
import { BoldButton } from "../../../components/BoldButton";
import { PackOrCuponSelector } from "../../Appointments";
import { getRandomUUID } from '../../../constants.js';
import { getGlobalClientInfo } from "../../../hooks/clientInfo.js";
import { EmailInput } from "../../../components/EmailInput/index.js";

let checkerOn = false;

export default class UserAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.root = props.root;
    this.state = {
      alert: null,
      alertColor: "green",
      alertActions: null,
      modal: null,
      data: null,
      clientInfo: null
    };
    if (this.data.status == APPO_STATUS_CODES.PENDING_PAY.code) {
      this.alert(
        "Estamos validando tu pago, este proceso puede tardar varios minutos...",
        colorPalletes[currentScheme].second
      );
    }

    if (this.data.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code) {
      this.alert(
        "Recuerda, confirma tu cita almenos dos horas antes de la hora programada",
        colorPalletes[currentScheme].second
      );
    }

    // if (this.data.status == APPO_STATUS_CODES.PAYMENT_FAILED.code) {
    //   this.alert(
    //     "Tu pago ha sido rechazado, porfavor intentalo nuevamente.",
    //     colorPalletes[currentScheme].secondShadow
    //   );
    // }
  }

  componentWillUnmount() {
    checkerOn = false;
  }

  payment() {
    window.open(`/payments?appointment=${this.data._id}`, "_self");
  }

  async alert(msg, alertColor) {
    await sleep(1000);
    this.setState(() => ({ alert: msg, alertColor: alertColor }));
  }

  async deleteAppointment() {
    const id = this.data._id;
    const root = this.root;
    const comp = this;
    let callback = async () => {
      let response = await deleteAppointment(id);
      if (!response) {
        comp.setState(() => ({
          alert:
            "No puedes cancelar esta cita mientras este en proceso de pago",
          alertColor: "var(--primary-shadow)",
          alertActions: null,
        }));
      } else {
        checkerOn = false;
        root.setComponent(Dates, "Citas Programadas");
      }
    };
    this.setState(() => ({
      alert: "Quieres borrar esta cita?",
      alertColor: "var(--primary-shadow)",
      alertActions: [
        {
          label: "borrar cita",
          callback: callback,
        },
      ],
    }));
  }

  async confirmAppointment() {
    let appointmentId = this.data._id;
    let resp = await fetch("/api/v1/appointments/confirm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: appointmentId,
      }),
    });
    if (resp.status == 200) {
      let data = await resp.json();
      let updatedData = await getSingleAppointment(appointmentId);
      if (updatedData) {
        this.data.status = data.status;
        this.setState(() => ({data: updatedData}));
      }
      
    }
  }

  async closealert(now = false) {
    if (!now) {
      await sleep(30000);
    }
    this.setState(() => ({ alert: null }));
  }

  async discountTransform(id) {
    const req = await fetch("/api/v1/discounts/transform", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        source: "appointments",
        id: id,
      }),
    });
    if (req.status == 200) {
      window.open("/?section=packs", "_self");
    }
  }

  async converToCupon(revert=false) {
    const comp = this;

    let callback = async () => {
      comp.discountTransform(comp.data._id);
    };

    this.setState(() => ({
      alert:
        !revert ?
        "Esta accion elimina esta cita y genera un bono equivalente a lo que pagaste, puedes usar ese bono en cualquier otro servicio.\n\nQuieres completar esta acción?"
        :
        "Esta accion elimina esta cita y devuelve el cupo a tu paquete\n\nQuieres completar esta accion?"
        ,
      alertColor: "var(--warning)",
      alertActions: [
        {
          label: !revert ? "Convertir en Cupon" : "Revertir al Paquete",
          callback: callback,
        },
      ],
    }));
  }

  updatePackOrDiscount(discountEntity, id, data) {
    if (!discountEntity) {
      this.data["cuponId"] = null;
      this.data["packId"] = null;
    } else {
      this.data[discountEntity] = id;
    }
    if (discountEntity == 'packId') {
      this.data['cuponId'] = null;
    } else if (discountEntity == "cuponId") {
      this.data['packId'] = null;
    }
  
    this.data['entityData'] = data;
    this.setState(()=>({modal: null}));
  }

  async updateAppointmentDiscount() {
    const resp = await fetch("/api/v1/appointments", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            _id: this.data._id,
            pack_id: this.data.packId,
            cupon_id: this.data.cuponId
        }),
    });

    if (resp.status == 201) {
        let data = await resp.json();
        data = data.data;
        this.setState(()=>({data: data}));
    }
  }

  async selectPacksOrCupons() {
    let data = {
      packs: [],
      cupons: [],
      id: this.data.packId || this.data.cuponId,
      service_id: this.data.service_id,
      data: this.data.entityData || null,
      entity: this.data.packId ? "packId" : "cuponId",
    };
    this.setState(() => ({
      modal: (
        <PackOrCuponSelector data={data} parent={this}></PackOrCuponSelector>
      ),
    }));
  }

  async checkEmailForEvent() {
    let data = this.data;
    if (this.state.data) {
        data = this.state.data
    }
    let clientInfo = await getGlobalClientInfo();
    if (clientInfo && !clientInfo.email) {
      this.setState(()=>({
        clientInfo: clientInfo,
        modal: <EmailInput message="Ingresa tu correo para crear el envento en el calendario" callback={()=>{
          window.open(`/?section=appointment&id=${data._id}`, "_self");
        }}></EmailInput>
      }))
    }
  }

  render() {
    let data = this.data;
    if (this.state.data) {
        data = this.state.data
    }
    let date = `Dia: ${dayNames[data.date.weekDay]}, ${
      data.date.day
    } ${monthNames[data.date.month - 1]}`;
    let hour =
      data.time > 12
        ? `Hora: ${data.time - 12} pm`
        : `Hora: ${data.time} am`;
    let payment;
    // // console.log(data);

    if (!this.state.clientInfo) {
      this.checkEmailForEvent();
    }
    

    if (
      data.pay_link &&
      data.pay_link.amount &&
      (data.status == APPO_STATUS_CODES.PAYMENT_FAILED.code ||
        data.status == APPO_STATUS_CODES.UNPAID_UNCONFIRMED.code)
    ) {
      payment = (
        <>
          <BoldButton key={getRandomUUID()} data={data.pay_link}></BoldButton>
        </>

        // <PayButton key={newUUID()} parent={this} link={data.pay_link ? data.pay_link.link : null} data={data}></PayButton>
      );
    }

    if (this.state.alert) {
      this.closealert();
    }

    let location;

    if (data.status == APPO_STATUS_CODES.PAID_CONFIRMED.code) {
      if (data.location == "online") {
        location = (
          <div className="location-details online" onClick={()=>{
            window.open(data.event.conferenceData.entryPoints[0].uri);
          }}>
            <h1>Link de tu sesion</h1>
            <img className="icon"></img>
          </div>
        );
      } else {
        location = (
          <div className="location-details">
            <h1>Ver Ubicacion</h1>
            <img className="icon"></img>
          </div>
        );
      }
    }

    let confirm;

    if (
      data.status &&
      data.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code
    ) {
      confirm = (
        <div className="confirm-action">
          <h1 onClick={() => this.confirmAppointment()}>Confirmar</h1>
        </div>
      );
    }

    let discountOptions;

    if (data.entityData) {
      discountOptions = (
        <>
          <p className="pack-btn" onClick={() => this.selectPacksOrCupons()}>{`${
            data.packId ? `100% OFF` : `Cupon por $ ${COP(data.entityData.amount)}`
          }`}</p>
          {data.entityData ? <p className="next-btn"
            onClick={()=>this.updateAppointmentDiscount()}>Aplicar Descuento</p> : null}
        </>
      );
    } else if (data.status == APPO_STATUS_CODES.UNPAID_UNCONFIRMED.code) {
        discountOptions = <p className="pack-btn" onClick={() => this.selectPacksOrCupons()}>Usar Cupon o Paquete</p>
    }

    return (
      <>
        <div className="user-appointment animated-intro">
          {this.state.alert && (
            <div
              className="alert-container animated-intro"
              style={{ backgroundColor: this.state.alertColor }}
            >
              <img
                className="icon icon-cancel"
                onClick={() => this.closealert(true)}
              ></img>
              <pre>{this.state.alert}</pre>
              {this.state.alertActions ? (
                <div>
                  {this.state.alertActions.map((action) => {
                    return (
                      <button
                        style={{ color: `${this.state.alertColor} !important` }}
                        onClick={() => action.callback()}
                      >
                        {action.label}
                      </button>
                    );
                  })}
                </div>
              ) : null}
            </div>
          )}
          <img
            className="user-appointment-img"
            src={data.service.image}
          ></img>
          <h1>{data.service.name}</h1>
          <h1>({data.location})</h1>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1>
              con{" "}
              <span
                style={{
                  fontWeight: "bold",
                  display: "inline",
                  magin: 0,
                  padding: 0,
                  fontSize: "18px",
                }}
              >
                {data.service.therapist}
              </span>
            </h1>
            <img
              className="icon user-appointment-therapist"
              src={data.service.therapist_profile}
            ></img>
          </div>

          <p>{date}</p>
          <h1>{hour}</h1>
          {data.pack_id ? (
            <div className="appointment-pack-link">
              <h1>Paquete</h1>
              <p>{data.pack_id.slice(0, 6)}</p>
            </div>
          ) : (
            ""
          )}

          {payment}
          {confirm}
          {(data.status == APPO_STATUS_CODES.UNPAID_UNCONFIRMED.code ||
            data.status == APPO_STATUS_CODES.PAYMENT_FAILED.code) && (
            <span>
              Haz tu pago, confirma tu cita y tendrás acceso a la invitacion
              para tu sesion
            </span>
          )}
          {data.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code &&
            (data.location == "online" ? (
              <span>Confirma tu cita para ver el link de la llamada</span>
            ) : (
              <span>Confirma tu cita para ver la ubicacion en el mapa</span>
            ))}

          {location}

          <div className="nav-ops">
            {discountOptions}
            <p
              onClick={() => {
                checkerOn = false;
                this.root.setComponent(AppointmentsConfig, null, {
                  service: data.service,
                  plan: data.service.plans[data.plan],
                  _id: data._id,
                  status: data.status,
                  packId: data.pack_id,
                });
              }}
            >
              Cambiar Fecha
            </p>
            {
            (
                data.status == APPO_STATUS_CODES.PAID_CONFIRMED.code ||
                data.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code
            ) && (!data.pack_id) ? (
              <p onClick={() => this.converToCupon()}>Convertir en Cupon</p>
            ) : (
              ""
            )}

{
            (
                data.status == APPO_STATUS_CODES.PAID_CONFIRMED.code ||
                data.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code
            ) && (data.pack_id) ? (
              <p onClick={() => this.converToCupon(true)}>Revertir al paquete</p>
            ) : (
              ""
            )}

            {data.status == APPO_STATUS_CODES.PAID_CONFIRMED.code ||
            data.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code ? (
              ""
            ) : (
              <p onClick={() => this.deleteAppointment()}>Cancelar Cita</p>
            )}
            <p
              onClick={(e) => {
                checkerOn = false;
                this.root.setComponent(Dates, "Citas Programadas");
              }}
            >
              Regresar
            </p>
          </div>
          <p className="policy">
            Recuerda nuestra <span>Política de Cambios y cancelaciones</span>
          </p>
        </div>
        {this.state.modal}
      </>
    );
  }
}

class PayButton extends React.Component {
  constructor(props) {
    super(props);
    this.link = props.link;
    this.data = props.data;
    this.parent = props.parent;
    this.state = {
      error: null,
      status: this.data.status,
      checking:
        this.link && this.data.status == APPO_STATUS_CODES.PENDING_PAY.code
          ? true
          : false,
    };
  }

  async payClicked() {
    let res = await fetch("/api/v1/appointments/payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        appointment: this.data._id,
      }),
    });
    if (res.status == 200) {
      window.open(this.link);
      this.data.status = APPO_STATUS_CODES.PENDING_PAY.code;
      this.parent.alert(
        "Estamos Validando tu pago...",
        colorPalletes[currentScheme].second
      );

      checkerOn = true;
      this.initPayChecker();
      this.setState(() => ({ status: this.data.status, checking: true }));
    }
  }

  async initPayChecker() {
    if (!checkerOn) {
      // // console.log("Stop Checker");
      return;
    }
    // // console.log("initPayChecker");
    await sleep(8000);

    let appointment = await getSingleAppointment(this.data._id);
    if (appointment) {
      appointment = appointment[0];
    }
    // // console.log("Appointment Data:");
    // // console.log(appointment);

    if (
      appointment.status &&
      appointment.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code
    ) {
      this.data.status = appointment.status;
      this.setState(() => ({ status: this.data.status, checking: false }));
    } else if (appointment.status == APPO_STATUS_CODES.PENDING_PAY.code) {
      this.data.status = APPO_STATUS_CODES.PENDING_PAY.code;
      // // console.log("Status: Pending, retrying");
      this.initPayChecker();
    } else if (
      appointment.status &&
      appointment.status == APPO_STATUS_CODES.PAYMENT_FAILED.code
    ) {
      this.data.status = APPO_STATUS_CODES.PAYMENT_FAILED.code;
      this.parent.alert("Tu pago fue rechazado intentalo nuevamente.", "red");
      this.setState(() => ({ status: this.data.status, checking: false }));
    }
  }

  render() {
    // // console.log("PayButton state:", this.state);

    if (!this.link) {
      return (
        <div className="pay-button pending">
          <h1>Generando link de pago</h1>
          <img className="icon"></img>
        </div>
      );
    }

    if (
      this.state.status == APPO_STATUS_CODES.PENDING_PAY.code &&
      this.state.checking &&
      !checkerOn
    ) {
      // // console.log("InitPaychecker");
      checkerOn = true;
      this.initPayChecker();
    } else {
      // // console.log(this.state.status);
      // // console.log(this.state.checking);
    }
    if (this.state.status == APPO_STATUS_CODES.UNPAID_UNCONFIRMED.code) {
      return (
        <div className="pay-button" onClick={() => this.payClicked()}>
          <h1>Pagar Ahora</h1>
          <img className="icon"></img>
        </div>
      );
    } else if (this.state.status == APPO_STATUS_CODES.PENDING_PAY.code) {
      return (
        <div
          className="pay-button pending"
          onClick={() => window.open(this.link)}
        >
          <h1>Procesando tu pago...</h1>
          <img className="icon icon-waiting"></img>
        </div>
      );
    } else if (
      this.state.status == APPO_STATUS_CODES.PAID_CONFIRMED.code ||
      this.state.status == APPO_STATUS_CODES.PAID_UNCONFIRMED.code
    ) {
      return (
        <div className="pay-button paid">
          <h1>Confirmar Asistencia</h1>
          <img className="icon icon-paid"></img>
        </div>
      );
    } else if (this.state.status == APPO_STATUS_CODES.PAYMENT_FAILED.code) {
      return (
        <div className="pay-button failed" onClick={() => this.payClicked()}>
          <h1>Reintentar Pago</h1>
          <img className="icon icon-paid"></img>
        </div>
      );
    }
  }
}

export { checkerOn };
