import React from "react";
import {
  deleteProduct,
  getProducts,
  saveProduct,
} from "../../../hooks/products";
import { sleep } from "../../../App";
import "./style.css";
import { formatter, newUUID } from "../../../dateutils/names";
import { saveImage } from "../../../hooks/images";

class ProductManager extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.userInfo = props.userInfo;
    this.state = {
      products: null,
      expanded: false,
      accData: null,
    };
  }

  async getProducts() {
    if (this.userInfo) {
      let [products, accData] = await getProducts(
        this.userInfo && this.userInfo._id
      );
      if (!products) {
        products = [];
      }
      this.setState(() => ({ products: products, accData }));
    } else {
      await sleep(1000);
      this.setState(() => ({ products: null }));
    }
  }

  async toggle() {
    if (!this.state.products) {
      await this.getProducts();
      await sleep(100);
    }
    this.setState((p) => ({ expanded: !p.expanded }));
  }

  createNewProduct() {
    this.root.renderComponent(ProductCreator, null);
  }

  updateProduct(Product) {
    this.root.renderComponent(ProductCreator, Product);
  }

  render() {
    // if (!this.state.products) {
    //     this.getProducts();
    // }

    let productsView;

    if (this.state.products) {
      productsView = this.state.products.map((product) => {
        return (
          <div
            className="admin-product-detail"
            onClick={() => this.updateProduct(product)}
          >
            <div>
              {product.image && (
                <img className="icon" src={product.image}></img>
              )}
            </div>
            <div>
              <h1>{product.name}</h1>
              {product.public && <p className="public collapsed">Activo</p>}

              {!product.public && <p className="hidden collapsed">Inactivo</p>}

              {/* <p className="description">{Product.description}</p> */}
              {/* <p>{product._id}</p> */}
              {product.weight && <p>Peso: {product.weight} gramos</p>}
              {product.width && product.package_length && product.height && (
                <p>
                  {product.width}x{product.package_length}x{product.height} cm
                </p>
              )}
              {/* <p><span style={{fontWeight: 'bold'}}>pricees: </span>{product.prices.length}</p>
                            <p><span style={{fontWeight: 'bold'}}>Duracion: </span>{product.time} minutos</p> */}
            </div>
          </div>
        );
      });
    }
    return (
      <div
        className={`product-manager ${this.state.expanded ? "expanded" : ""}`}
      >
        <div className="service-main-actions" onClick={() => this.toggle()}>
          {this.state.expanded ? (
            <>
              <h1>Productos</h1>
              <div>
                <button onClick={() => this.createNewProduct()}>Agregar</button>
                <img className="icon icon-collapse-card"></img>
              </div>
            </>
          ) : (
            <>
              <h1>Productos</h1>
            </>
          )}
        </div>
        {this.state.expanded ? productsView : null}
        {this.state.expanded &&
        this.state.accData &&
        this.state.accData.metrics ? (

          <ChartBar data={this.state.accData} label="Cursos Vendidos"></ChartBar>
          // <div className="chart-bars" key={newUUID()}>
          //   <p></p>
          //   {this.state.accData && this.state.accData.metrics
          //     ? this.state.accData.metrics.map((metric) => {
          //         return (
          //           <div className="chart-bar">
          //             <p>
          //               {metric.name} ({metric.units})
          //             </p>
          //             <div className="bar">
          //               <p style={{ width: `${metric.percent}%` }}></p>
          //             </div>
          //           </div>
          //         );
          //       })
          //     : ""}
          // </div>
        ) : null}
      </div>
    );
  }
}


class ProductCreator extends React.Component {
  constructor(props) {
    super(props);

    this.root = props.root;
    this.data = props.data;
    this.owner = props.owner;
    this.price_template = {
      price: null,
      sessions: null,
      usd_price: null,
    };

    let data = props.data || {};

    this.state = {
      id: data._id || null,
      name: data.name || null,
      description: data.description || null,
      weight: data.weight || null,
      width: data.width || null,
      package_length: data.package_length || null,
      height: data.height || null,
      prices: data.prices || [],
      tempprice: null,
      image: data.image || null,
      public: data.public || false,
      priceEditor: false,
      index: null,
      alert: null,
      uploading: false,
    };
  }

  readImage(e) {
    let component = this;
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = (ev) => {
      let contents = ev.target.result;
      component.setState(() => ({ imageData: contents }));
      // component.setState(() => ({ tones: tones }));
    };
    reader.readAsDataURL(file);
  }

  readImage(e) {
    let component = this;
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onload = (ev) => {
      let contents = ev.target.result;
      component.setState(() => ({ icon: contents }));
      // component.setState(() => ({ tones: tones }));
    };
    reader.readAsDataURL(file);
  }

  uploadCover() {
    let component = this;
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.png,.jpeg";
    input.onchange = async (event) => {
      this.setState(() => ({ uploading: true }));

      let imageURL = await saveImage(event.target.files[0], this.state.image);
      component.setState(() => ({ image: imageURL, uploading: false }));
      component.saveProduct();
    };
    input.click();
  }

  updatetempprice(field, value) {
    this.setState((p) => {
      let tempprice = p.tempprice;
      if (!tempprice) {
        tempprice = {};
      }
      if (field == "price") {
        tempprice["real_price"] = Number(value) * (1 + 0.0399 + 0.015);
      }
      tempprice[field] = value;
      return {
        tempprice: tempprice,
      };
    });
  }

  async saveProduct() {
    let productTemplate = {
      name: this.state.name,
      description: this.state.description,
      prices: this.state.prices,
      image: this.state.image,
      weight: this.state.weight,
      width: this.state.width,
      package_length: this.state.package_length,
      height: this.state.height,
      id: this.data ? this.data._id : this.state.id,
      public: this.state.public,
      owner: this.owner,
    };

    let id = await saveProduct(productTemplate);
    if (!this.state.id) {
      this.setState(() => ({ id: id }));
    }
    // this.root.renderComponent(null, null);
  }

  async close() {
    this.saveProduct();
    this.root.renderComponent(null, null);
  }

  renderpriceEditor() {
    // // console.log("render price editor");
    let priceData = Object.assign({}, this.state.tempprice);
    return (
      <div className="price-editor-container">
        <div className="service-main-actions price-editor">
          <h1>Editor de price</h1>
          <button
            onClick={() =>
              this.setState((p) => {
                let prices = p.prices;
                if (p.index) {
                  prices[p.index - 1] = priceData;
                } else {
                  prices.push(priceData);
                }
                return {
                  priceEditor: false,
                  prices: prices,
                  tempprice: null,
                  index: null,
                };
              })
            }
          >
            Guardar
          </button>
        </div>
        <br></br>
        <label>Precio en (CUR)</label>
        <input
          type="number"
          onChange={(e) => this.updatetempprice("price", e.target.value)}
          value={priceData.price || undefined}
        ></input>
        <br></br>
        {/* <label>Precio en USD</label>
        <input
          type="number"
          onChange={(e) => this.updatetempprice("usd_price", e.target.value)}
          value={priceData.usd_price || undefined}
        ></input>
        <br></br>
        <label>Precio en EUR</label>
        <input
          type="number"
          onChange={(e) => this.updatetempprice("eur_price", e.target.value)}
          value={priceData.eur_price || undefined}
        ></input> */}
        <button
          onClick={() =>
            this.setState(() => ({ priceEditor: false, tempprice: null }))
          }
        >
          Cerrar
        </button>
        {/* <p>Los precios en otras divisas se calculan en base a la tasa de cambio vigente al momento de agendar la cita.</p> */}
      </div>
    );
  }

  renderpriceCard(index, price) {
    return (
      <div className="creator-price-card">
        <div>
          <p>
            Precio{" "}
            <span style={{ fontWeight: "bold" }}>
              {formatter.format(price.price)}
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              con tarifa {formatter.format(price.real_price)}
            </span>
          </p>
          {/* <p>
            Precio en USD:{" "}
            <span style={{ fontWeight: "bold" }}>${price.usd_price}</span>
          </p>
          <p>
            Precio en EUR:{" "}
            <span style={{ fontWeight: "bold" }}>${price.eur_price}</span>
          </p> */}
        </div>
        <div
          style={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="icon icon-delete"
            onClick={() => this.deleteprice(index)}
          ></img>
          <img
            className="icon icon-edit"
            onClick={() =>
              this.setState(() => ({ tempprice: price, index: index }))
            }
          ></img>
        </div>
      </div>
    );
  }

  async deleteProduct() {
    const root = this.root;
    const id = this.data._id;
    let callback = async () => {
      await deleteProduct(id);
      root.renderComponent(null, null);
    };
    this.setState(() => ({
      alert: {
        label: "Deseas Eliminar este taller?",
        actions: [
          {
            label: "Eliminar",
            callback: callback,
          },
        ],
        color: "var(--primary-shadow)",
      },
    }));
  }

  deleteprice(priceIndex) {
    let comp = this;

    let callback = async () => {
      comp.setState((p) => {
        let prices = p.prices;
        prices.splice(priceIndex, 1);
        if (prices.length == 1) {
          prices = [];
        }
        return {
          prices: prices,
          alert: null,
        };
      });
    };
    this.setState(() => ({
      alert: {
        label: "Deseas Eliminar este price?",
        actions: [
          {
            label: "Eliminar",
            callback: callback,
          },
        ],
        color: "var(--primary-shadow)",
      },
    }));
  }

  async closealert(now) {
    if (!now) {
      await sleep(10000);
    }
    this.setState(() => ({ alert: null }));
  }

  render() {
    return (
      <div className="product-creator">
        {this.state.alert && this.state.alert && (
          <div
            className="alert-container animated-intro"
            style={{ backgroundColor: this.state.alert.color }}
          >
            <img
              className="icon icon-cancel"
              onClick={() => this.closealert(true)}
            ></img>
            <p>{this.state.alert.label}</p>
            {this.state.alert.actions ? (
              <div>
                {this.state.alert.actions.map((action) => {
                  return (
                    <button onClick={() => action.callback()}>
                      {action.label}
                    </button>
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
        {(this.state.priceEditor || this.state.tempprice) &&
          this.renderpriceEditor()}
        <div className="service-main-actions save-product">
          <h1>Editor de Producto</h1>
          <button onClick={() => this.close()}>Guardar</button>
        </div>
        <div className="editor-content">
          {this.state.image ? (
            <div
              className={
                "service-main-actions cover " +
                (this.state.uploading ? "" : "active")
              }
              onClick={() => this.uploadCover()}
            >
              {this.state.uploading ? (
                <img className="icon icon-load"></img>
              ) : (
                <img src={this.state.image}></img>
              )}
              <p>
                {this.state.uploading ? "Subiendo imagen" : "Cambiar la imagen"}
              </p>
            </div>
          ) : (
            <div className="service-main-actions cover">
              <div>
                <img className="icon icon-add-cover"></img>
              </div>

              <p onClick={() => this.uploadCover()}>Agregar imagen</p>
            </div>
          )}

          {!this.state.public && (
            <div className="publish-header">
              <p>Producto Inactivo</p>
              <button
                className="public"
                onClick={async () => {
                  this.setState(() => ({ public: true }));
                  await sleep(100);
                  this.saveProduct();
                }}
              >
                Activar
              </button>
            </div>
          )}

          {this.state.public && (
            <div className="publish-header">
              <p>Producto activo</p>
              <button
                onClick={async () => {
                  this.setState(() => ({ public: false }));
                  await sleep(100);
                  this.saveProduct();
                }}
              >
                Desactivar
              </button>
            </div>
          )}

          <label>Nombre</label>
          <input
            value={this.state.name || ""}
            placeholder="..."
            onChange={(e) => this.setState(() => ({ name: e.target.value }))}
          ></input>

          <br></br>

          <label>Descripcion</label>
          <textarea
            value={this.state.description || ""}
            onChange={(e) =>
              this.setState(() => ({ description: e.target.value }))
            }
          ></textarea>
          <br></br>
          <br></br>
          <h1>Datos de empaque</h1>
          <div className="packaging-details-input">
            <div>
              <label>Peso (gramos)</label>
              <input
                type="number"
                value={this.state.weight}
                placeholder="..."
                onChange={(e) =>
                  this.setState(() => ({ weight: e.target.value }))
                }
              ></input>
            </div>
            <div>
              <label>Ancho (cm)</label>
              <input
                type="number"
                value={this.state.width}
                placeholder="..."
                onChange={(e) =>
                  this.setState(() => ({ width: e.target.value }))
                }
              ></input>
            </div>
            <div>
              <label>Largo (cm)</label>
              <input
                type="number"
                value={this.state.package_length}
                placeholder="..."
                onChange={(e) =>
                  this.setState(() => ({ package_length: e.target.value }))
                }
              ></input>
            </div>
            <div>
              <label>Alto (cm)</label>
              <input
                type="number"
                value={this.state.height}
                placeholder="..."
                onChange={(e) =>
                  this.setState(() => ({ height: e.target.value }))
                }
              ></input>
            </div>
          </div>

          <br></br>
          <div>
            <div className="service-main-actions price-creator">
              <h1>Precios</h1>
              {(!this.state.prices || this.state.prices.length == 0) && (
                <button
                  onClick={() =>
                    this.setState(() => ({ priceEditor: true, tempprice: {} }))
                  }
                >
                  Agregar Precio
                </button>
              )}
            </div>
            <div>
              {this.state.prices.map((price, index) => {
                return this.renderpriceCard(index + 1, price);
              })}
            </div>
          </div>

          <div className="nav-ops">
            {this.data && (
              <p className="accent-option" onClick={() => this.deleteProduct()}>
                Borrar Producto
              </p>
            )}
            <p onClick={() => this.close()}>Guardar y Salir</p>
          </div>
        </div>
      </div>
    );
  }
}

export { ProductCreator, ProductManager };
