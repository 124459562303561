import { formatter, monthNames, newUUID } from "../../../dateutils/names";
import React from "react";
import { BaseComponent } from "../base";
import "./style.css";
import { Course } from "../../Courses";
import { WorkshopDetail } from "../../Workshops/WorkshopDetail";
import TherapyDetail from "../../TherapyDetail";

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.label = props.label;
    this.option = props.option;
    this.color = props.color || "var(--second)";
    this.function = props.callback;
    this.x = props.x;
    this.y = props.y;
    this.metrics = props.metrics;
    let allSales = this.metrics.map((m) => m[this.y]);
    this.max = Math.max(...allSales) * 1.5;

    this.state = {};
  }

  callback(option) {
    this.function(option);
  }

  render() {
    console.log(this.metrics);
    return (
      <div className="metrics-component animated-intro">
        <h1>{this.label}</h1>
        <div className="metric-container">
          {this.metrics.map((metric) => {
            return (
              <div className="metric-line">
                <div>
                  <img
                    style={{
                      height: `${(metric[this.y] * 100) / this.max}%`,
                      backgroundColor: this.color,
                    }}
                  ></img>
                  <p
                    style={{
                      bottom: `${(metric[this.y] * 100) / this.max}%`,
                      color: this.color,
                    }}
                  >
                    {metric[this.y]}
                  </p>
                </div>

                <p>{metric[this.x]}</p>
              </div>
            );
          })}
        </div>
        <button onClick={() => this.callback(this.data)}>{this.option}</button>
      </div>
    );
  }
}

let productStats = () => [
  {
    month: "Enero",
    sales: Math.floor(Math.random() * 60),
  },
  {
    month: "Febrero",
    sales: Math.floor(Math.random() * 60),
  },
  {
    month: "Marzo",
    sales: Math.floor(Math.random() * 60),
  },
  {
    month: "Abril",
    sales: Math.floor(Math.random() * 60),
  },
  {
    month: "Mayo",
    sales: Math.floor(Math.random() * 60),
  },
];

function filterByMonth(metrics) {
  // START Month Filter
  let filteredMetrics = [];
  for (let [key, entity] of Object.entries(metrics)) {
    let entityMetric = {
      _id: entity.data._id,
      name: entity.data.name,
      metrics: [],
    };
    let monthMetrics = {};

    // filter each metric from the entity
    for (let metric of entity.metrics) {
      let oldMetric = monthMetrics[metric.date.month];
      if (!oldMetric) {
        oldMetric = {
          sales: 0,
        };
      }
      oldMetric.month = monthNames[metric.date.month - 1];
      oldMetric.sales += 1;
      monthMetrics[metric.date.month] = oldMetric;
    }

    for (let [m, filteredMonth] of Object.entries(monthMetrics)) {
      entityMetric.metrics.push(filteredMonth);
    }
    filteredMetrics.push(entityMetric);
  }
  console.log(filteredMetrics);
  // end Month Filter
  return filteredMetrics;
}

function filterByDays(metrics, month) {
  // START Month Filter
  let filteredMetrics = [];
  for (let [key, entity] of Object.entries(metrics)) {
    let entityMetric = {
      _id: entity.data._id,
      name: entity.data.name,
      metrics: [],
    };
    let dayMetrics = {};

    // filter each metric from the entity
    for (let metric of entity.metrics) {
      if (metric.date.month == month) {
        let oldMetric = dayMetrics[metric.date.day];
        if (!oldMetric) {
          oldMetric = {
            sales: 0,
          };
        }
        oldMetric.month = `${metric.date.day} ${monthNames[month - 1].slice(0, 3)}`;
        oldMetric.sales += 1;
        dayMetrics[metric.date.day] = oldMetric;
      }
    }

    for (let [m, filteredDay] of Object.entries(dayMetrics)) {
      entityMetric.metrics.push(filteredDay);
    }
    filteredMetrics.push(entityMetric);
  }
  console.log(filteredMetrics);
  // end Month Filter
  return filteredMetrics;
}

let adminStatus = ["registrado", "activo", "inactivo"];

class AdminDetail extends BaseComponent {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.state = {
      currentTab: "services",
      userMetrics: null, // this.data.metrics,
      filterRange: "month",
      //   userMetrics: {
      //     products: [
      //       {
      //         _id: "",
      //         name: "Vela de incienso",
      //         metrics: productStats(),
      //       },

      //       {
      //         _id: "",
      //         name: "Jabon del Culito",
      //         metrics: productStats(),
      //       },
      //     ],
      //     services: [
      //       {
      //         _id: "",
      //         name: "Terapia Integrativa",
      //         metrics: productStats(),
      //       },

      //       {
      //         _id: "",
      //         name: "Consulta Psicoterapia",
      //         metrics: productStats(),
      //       },
      //     ],
      //     courses: [
      //       {
      //         _id: "",
      //         name: "Conocer el ser",
      //         metrics: productStats(),
      //       },

      //       {
      //         _id: "",
      //         name: "Botanica",
      //         metrics: productStats(),
      //       },
      //     ],
      //     workshops: [
      //       {
      //         _id: "",
      //         name: "Taller de perritos",
      //         metrics: productStats(),
      //       },

      //       {
      //         _id: "",
      //         name: "Taller de chalupas",
      //         metrics: productStats(),
      //       },
      //     ],
      //   },
    };
  }

  async getMetrics() {
    let request = await fetch(`/api/v1/management/admin-data?id=${this.data._id}`);
    if (request.status == 200) {
      let data = await request.json();
      this.setState(()=>({
        userMetrics: data.metrics.metrics
      }))
      console.log(request);
    }
  }

  filter(config, metrics) {
    if (config.by == "month") {
      return filterByMonth(metrics);
    } else {
      return filterByDays(metrics, 11);
    }
  }

  render() {

    if (!this.state.userMetrics) {
      this.getMetrics();
      return <></>
    }
    console.log(this);
    console.log(this.state.userMetrics);

    let filteredMetrics;
    if (this.state.userMetrics) {
      let metrics = this.state.userMetrics[this.state.currentTab];
      if (metrics) {
        filteredMetrics = this.filter({ by: this.state.filterRange }, metrics);
        console.log(filteredMetrics);
      }
    }
    return (
      <div className="admin-manager-details animated-intro">
        <div>
          <div className="admin-detail-header">
            <img className="icon icon-empty-profile"></img>
            <h1>{this.data.name}</h1>
            <p>{this.data.professional_name}</p>
          </div>

          <div>
            <h1>User Status</h1>
            <select defaultValue={0}>
              {adminStatus.map((opt, index) => {
                return <option value={index}>{opt}</option>;
              })}
            </select>
            <button>Apply Changes</button>
          </div>
        </div>

        <div>
          <h1>Customers / Patients</h1>
          <div className="user-tags">
            <div className="user-tag-item">
              <img className="icon icon-empty-profile"></img>
              <p>Username Surename</p>
            </div>

            <div className="user-tag-item">
              <img className="icon icon-empty-profile"></img>
              <p>Username</p>
            </div>

            <div className="user-tag-item">
              <img className="icon icon-empty-profile"></img>
              <p>Username</p>
            </div>
          </div>
        </div>

        <div>
        <h1>Categorias</h1>
          <div className="course-tags-container">
            
            {this.state.userMetrics &&
              Object.entries(this.state.userMetrics).map(([key, value]) => {
                return (
                  <div
                    className={`tag-item ${
                      this.state.currentTab == key ? "active" : ""
                    }`}
                  >
                    {/* <img className={`icon`}></img> */}
                    <p
                      onClick={() => this.setState(() => ({ currentTab: key }))}
                    >
                      {key}
                    </p>
                  </div>
                );
              })}
          </div>

          <div>
            <h1>Agrupar por</h1>
            <div className="course-tags-container">
              <div
                className={`tag-item ${
                  this.state.filterRange == "month" ? "active" : ""
                }`}
              >
                <p
                  onClick={() =>
                    this.setState(() => ({ filterRange: "month" }))
                  }
                >
                  Meses
                </p>
              </div>

              <div
                className={`tag-item ${
                  this.state.filterRange == "days" ? "active" : ""
                }`}
              >
                <p
                  onClick={() => this.setState(() => ({ filterRange: "days" }))}
                >
                  Dias
                </p>
              </div>
            </div>
          </div>

          <div>
            {filteredMetrics &&
              filteredMetrics.map((tabOption) => {
                return (
                  <div>
                    <Chart
                      color={
                        this.state.filterRange == "month"
                          ? "var(--second)"
                          : "var(--primary-shadow)"
                      }
                      key={newUUID()}
                      label={tabOption.name}
                      metrics={tabOption.metrics}
                      x={"month"}
                      y={"sales"}
                      option="Ver Detalle"
                      callback={() => {}}
                    ></Chart>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="nav-ops">
          <p onClick={() => this.root.renderComponent(null, null)}>Salir</p>
        </div>
      </div>
    );
  }
}

export { AdminDetail };
