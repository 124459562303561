import React from "react";
import { newUUID, sleep } from "../../../../dateutils/names";
import { CertificateDownloadPage } from "../../Certificates";

class BasePageProgress extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;

    this.state = {
      controlOpacity: 1,
    };
  }

  async close() {
    this.root.root.setState(() => ({
      component: null,
      data: null,
    }));
  }

  async forward() {
    let index = this.root.data.index;
    let currentPage = this.root.state.currentPage;

    if (this.root.state.currentPage < this.root.state.pages.length) {
      this.root.root.updateCourseProgress(index, currentPage + 1);
      
      await sleep(200);
      this.root.setState((p) => ({ currentPage: p.currentPage + 1 }));
    }
  }

  backward() {
    if (this.root.state.currentPage > 0) {
      this.root.setState((p) => ({ currentPage: p.currentPage - 1 }));
    }
  }

  render() {
    let index = this.root.state.currentPage;
    let pages = this.root.state.pages.length;

    if (index >= pages.length) {
      return <div>
        <h1>Completaste este modulo</h1>
        <p>Felicitaciones!!</p>
      </div>
    }

    let pagesBits = [];

    for (let i = 0; i < pages; i++) {
      let calculatedWidth = 100 / pages;
      let style;
      if (index == i) {
        style = "current";
      } else if (i < index) {
        style = "passed";
      }
      pagesBits.push(
        <div
          className={"progress-bit-block " + style}
          style={{ width: `calc(${calculatedWidth}% - 8px)` }}
        ></div>
      );
    }

    let progress = Math.round((index * 100) / pages);
    return (
      <div className="preview-page">
        <div
          className="preview-headers"
          style={{ opacity: this.state.controlOpacity }}
        >
          <img
            className="icon icon-close-preview"
            onClick={() => this.close()}
          ></img>
          <h1>
            {"Modulo"} {this.root.data.index}
          </h1>
          <p>{this.root.data.title}</p>
          {/* <p>
            {"Pagina"} {this.root.state.currentPage + 1}
          </p> */}
        </div>

        {this.view()}

        <div
          className="preview-footer"
          style={{ opacity: this.state.controlOpacity }}
        >
          <img
            className="icon icon-chevron-left"
            onClick={() => this.backward()}
          ></img>
          <div className="preview-progress">{pagesBits}</div>
          <img
            className="icon icon-chevron-right"
            onClick={() =>
              this.forward()
            }
          ></img>
          <div className="progress-percent">
            <p>Progreso </p>
            <h1>{progress}%</h1>
          </div>
        </div>
      </div>
    );
  }
}

class AudioPageProgress extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.audioRef = React.createRef();
    this.state = {
      play: false,
      tick: false,
      elapsed: null,
      currentTime: null,
      duration: null,
    };
  }

  async tick() {
    if (this.state.tick && this.audioRef.current) {
      let duration = this.audioRef.current.duration;
      let currentTime = this.audioRef.current.currentTime;
      let elapsed = Math.round((currentTime * 100) / duration);
      this.setState((p) => ({
        elapsed: elapsed,
        currentTime: currentTime,
        duration: duration,
      }));
      await sleep(1000 / 15);
      this.tick();
    }
  }

  async play() {
    if (this.audioRef.current) {
      if (this.audioRef.current.paused) {
        this.audioRef.current.play();
        this.setState(() => ({ play: true, tick: true }));
        await sleep(200);
        this.tick();
      } else {
        this.audioRef.current.pause();
        this.setState(() => ({ play: false, tick: false }));
      }
    }
  }
  close() {
    this.setState(() => ({ play: false, tick: false }));
    super.close();
  }

  render() {
    let elapsed = "00:00";
    let duration = "00:00";

    if (this.state.elapsed) {
      let eM = String(Math.floor(this.state.currentTime / 60)).padStart(2, "0");
      let eS = String(Math.floor(this.state.currentTime % 60)).padStart(2, "0");
      elapsed = `${eM}:${eS}`;

      let dM = String(Math.floor(this.state.duration / 60)).padStart(2, "0");
      let dS = String(Math.floor(this.state.duration % 60)).padStart(2, "0");
      duration = `${dM}:${dS}`;
    }

    return (
      <div className="preview-audio-block">
        <div className="audio-status-control">
          <img
            className={
              "icon " +
              (this.state.play ? "icon-audio-pause" : "icon-audio-play")
            }
            onClick={() => this.play()}
          ></img>
          <img
            className="icon icon-wave-form preview"
            style={{
              background: `linear-gradient(90deg, var(--elapsed) ${
                this.state.elapsed || 0
              }%, white ${this.state.elapsed || 0}%)`,
            }}
          ></img>
        </div>
        <div className="audio-position-control">
          <p>
            {elapsed} / {duration}
          </p>
          <div className="audio-timeline">
            <div
              className="audio-timeline-progress"
              style={{
                width: this.state.elapsed ? `${this.state.elapsed}%` : "0px",
              }}
            ></div>
            <div
              className={
                "audio-timeline-pointer " + (this.state.play ? "active" : "")
              }
              style={{
                left: this.state.elapsed ? `${this.state.elapsed}%` : "0px",
              }}
            ></div>
          </div>
        </div>
        <audio
          ref={this.audioRef}
          src={this.data.src}
          onEnded={() => this.setState(() => ({ play: false, tick: false }))}
        ></audio>
      </div>
    );
  }
}

class BlockPageProgress extends BasePageProgress {
  view() {
    return (
      <div className="preview-block">
        {this.data.content.map((block) => {
          if (block.type == "title") {
            return <h1>{block.text}</h1>;
          } else if (block.type == "paragraph") {
            return <p>{block.text}</p>;
          } else if (block.type == "image") {
            return <img src={block.src}></img>;
          } else if (block.type == "audio") {
            return <AudioPageProgress data={block}></AudioPageProgress>;
          }
        })}
      </div>
    );
  }
}

class VideoPageProgress extends BasePageProgress {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      play: false,
      canplay: false,
      controlOpacity: 1,
      stopOpacityChange: false,
    };
    this.key = "video-src-element";
  }

  async close() {
    if (this.videoRef.current) {
      if (!this.videoRef.current.paused) {
        this.playPause();
      }
    }

    await sleep(1000);

    this.root.root.setState(() => ({
      component: null,
      data: null,
    }));
  }

  playPause() {
    let playStatus = this.state.play;
    if (!playStatus) {
      this.videoRef.current.play();
    } else {
      this.videoRef.current.pause();
      this.setState((p) => ({
        play: !p.play,
        tick: false,
        stopOpacityChange: true,
        opacity: 1,
      }));
    }
  }

  async tick() {
    if (this.state.tick) {
      try {
        let duration = this.data.duration;
        let currentTime = this.videoRef.current.currentTime;
        let elapsed = currentTime; // Math.round((currentTime * 100) / duration);
        this.setState((p) => ({ elapsed: elapsed }));
        await sleep(1000 / 15);
        this.tick();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async waitForVideo() {
    if (!this.videoRef.current) {
      await sleep(600);
      this.waitForVideo();
      return;
    } else {
      if (this.videoRef.current.readyState == 4) {
        this.videoRef.current.addEventListener("play", async (e) => {
          this.setState((p) => ({
            play: true,
            tick: true,
            stopOpacityChange: false,
            controlOpacity: 1,
          }));
          await sleep(200);
          this.tick();
          this.hideControls();
        });

        this.videoRef.current.addEventListener("pause", (e) => {
          this.setState((p) => ({
            play: false,
            tick: false,
            stopOpacityChange: true,
            controlOpacity: 1,
          }));
        });

        this.setState(() => ({
          canplay: true,
          play: true,
          tick: true,
          stopOpacityChange: false,
          controlOpacity: 1,
        }));
        await sleep(200);
        this.tick();
        this.hideControls();
      } else {
        await sleep(600);
        this.waitForVideo();
      }
    }
  }

  selectTime(e) {
    let rect = e.target.getBoundingClientRect();
    let width = rect.width;

    let pos = e.clientX - rect.left - 12;
    if (pos > width) {
      pos = width;
    }
    let percent = (pos * 100) / width;
    let time = Math.round((this.data.duration * percent) / 100);
    let paused = this.videoRef.current.paused;
    if (!paused) {
      this.videoRef.current.pause();
    }

    this.videoRef.current.currentTime = time;

    if (!paused) {
      this.videoRef.current.play();
    } else {
      this.setState(() => ({ elapsed: percent }));
    }
  }

  moveTime(time) {
    if (this.videoRef.current) {
      let paused = this.videoRef.current.paused;
      let newTime = this.videoRef.current.currentTime + time;
      if (newTime < 0) {
        newTime = 0;
      }
      this.videoRef.current.currentTime = newTime;
      if (paused) {
        this.setState(() => ({
          elapsed: (newTime * 100) / this.data.duration,
        }));
      }
    }
  }

  async hideControls() {
    await sleep(2600);
    let stop = this.state.stopOpacityChange;

    // console.log("hide controls");
    // console.log(stop);

    if (!stop) {
      this.setState(() => ({ controlOpacity: 0 }));
    }
  }

  async showControls() {
    // console.log("showcontrols");
    // console.log(this.state);
    if (this.state.controlOpacity < 1 && !this.state.stopOpacityChange) {
      this.setState(() => ({ controlOpacity: 1, stopOpacityChange: false }));
      this.hideControls();
    }
  }

  view() {
    if (!this.state.canplay) {
      this.waitForVideo();
    }

    let ellapsed = "00:00";
    let duration = "00:00";
    duration = this.data.duration;

    let dM = String(Math.floor(duration / 60)).padStart(2, "0");
    let dS = String(Math.ceil(duration % 60)).padStart(2, "0");
    duration = `${dM}:${dS}`;

    if (this.state.elapsed) {
      ellapsed = Math.floor(this.state.elapsed);
      let elM = String(Math.floor(ellapsed / 60)).padStart(2, "0");
      let elS = String(Math.ceil(ellapsed % 60)).padStart(2, "0");

      ellapsed = `${elM}:${elS}`;
    }

    return (
      <>
        <div
          className="video-controls"
          onClick={() => this.showControls()}
          style={{ opacity: this.state.controlOpacity }}
        >
          <div className="play-controls">
            <img
              className="icon icon-backward"
              onClick={() => this.moveTime(-5)}
            ></img>
            <img
              className={
                "icon " + (this.state.play ? "icon-pause" : "icon-play")
              }
              onClick={() => this.playPause()}
            ></img>
            <img
              className="icon icon-forward"
              onClick={() => this.moveTime(2.5)}
            ></img>
            <div className="timeline-container">
              <div className="video-timeline">
                <div
                  className="video-time-selector"
                  onClick={(e) => this.selectTime(e)}
                ></div>
                <div
                  className="video-elapsed"
                  style={{
                    width: this.state.elapsed
                      ? `${(this.state.elapsed * 100) / this.data.duration}%`
                      : "0",
                  }}
                ></div>
                <div
                  className="video-pointer"
                  style={{
                    left: this.state.elapsed
                      ? `${(this.state.elapsed * 100) / this.data.duration}%`
                      : "0",
                  }}
                ></div>
              </div>
            </div>
            <p className="ellapsed-time" key={newUUID()}>
              {ellapsed} / {duration}
            </p>
          </div>
        </div>
        {!this.state.canplay && (
          <div className="preview-loading">
            <p>Cargando</p>
            <br></br>
            <img className="icon icon-course-loading"></img>
          </div>
        )}
        <video ref={this.videoRef} key={this.key} autoPlay>
          <source src={this.data.src}></source>
        </video>
      </>
    );
  }
}

class QuizPageProgress extends BasePageProgress {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      started: false,
      currentQuestion: 0,
      maxQuestion: 0,
      answers: new Array(this.data.content.length).fill(0),
    };
  }

  calculateScore() {
    let questions = this.data.content.length;
    let score = 0;
    let percent = 100 / questions;
    let qty = 0;
    for (let i = 0; i < questions; i++) {
      if (this.state.answers[i] == this.data.content[i].answer) {
        score += percent;
        qty += 1;
      }
    }

    return [score, qty];
  }

  renderResult() {
    let [score, qty] = this.calculateScore();

    return (
      <div className="preview-results">
        <h1>Resultados</h1>
        <div className="score">
          <h1>{score}%</h1>
          <p>
            Acertaste {qty}/{this.data.content.length}
          </p>
        </div>
        {/* {this.data.content.map((question, qIndex) => {
            return (
              <div>
                <p>{question.question}</p>
                <p>{question.options[this.state.answers[qIndex] - 1]}</p>
  
                <p>Correct answer</p>
                <p>{question.options[question.answer - 1]}</p>
              </div>
            );
          })} */}
        {score == 100 && (
          <button className="passed" onClick={() => this.forward()}>
            Continuar
          </button>
        )}
        {score < 100 && (
          <button onClick={() => this.reset()}>Intentar de nuevo</button>
        )}
      </div>
    );
  }

  reset() {
    this.setState(() => ({
      started: false,
      currentQuestion: 0,
    }));
  }

  setAnswer(optionIndex, event) {
    this.setState((p) => {
      let answers = p.answers;
      answers[this.state.currentQuestion] = optionIndex + 1;
      return {
        answers: answers,
      };
    });
  }

  renderQuestion(question) {
    return (
      <div className="question-preview">
        <h2>Pregunta {this.state.currentQuestion + 1}</h2>
        <br></br>
        <p>{question.question}</p>
        {question.options.map((option, index) => {
          return (
            <div className="option-preview">
              <input
                checked={
                  this.state.answers[this.state.currentQuestion] == index + 1
                }
                type="checkbox"
                onChange={(e) => this.setAnswer(index, e)}
              ></input>
              <p>{option}</p>
            </div>
          );
        })}

        <button
          onClick={() =>
            this.setState((p) => ({ currentQuestion: p.currentQuestion + 1 }))
          }
        >
          Continuar
        </button>
      </div>
    );
  }

  renderStart() {
    return (
      <>
        <h1>{this.data.title}</h1>
        <p>{this.data.description}</p>
        <button onClick={() => this.setState(() => ({ started: true }))}>
          Comenzar
        </button>
      </>
    );
  }

  view() {
    return (
      <div className="preview-block preview-quiz">
        {!this.state.started && this.renderStart()}
        {this.state.started &&
          this.state.currentQuestion < this.data.content.length &&
          this.renderQuestion(this.data.content[this.state.currentQuestion])}
        {this.state.started &&
          this.state.currentQuestion == this.data.content.length &&
          this.renderResult()}
      </div>
    );
  }
}

class EndPage extends BasePageProgress {
  async nextModule() {
    // console.log(this.root.root.state.courseProgress.modules);
    // console.log(this.root.data.index - 1);
    this.root.root.updateCourseProgress(this.root.data.index, this.root.state.currentPage);
    await sleep(400);

    let moduleIndex = this.root.data.index;
    let module = this.root.root.data.modules[moduleIndex];
    if (module) {
      console.log("this means next module should be loaded");
      console.log(moduleIndex);
      console.log(module);
      this.root.root.openModule(module, moduleIndex + 1);
    } else {
      console.log("This means when the hole course is completed");
      this.root.root.renderComponent(CertificateDownloadPage, {});
    }
  }


  view() {
    return (
      <div className="preview-block preview-quiz">
        <h1>Terminaste este modulo</h1>
        <p>Felicitaciones!!</p>
        <button onClick={()=>this.nextModule()}>Siguiente Modulo</button>
      </div>
    )
  }
}

let pageTemplateMap = {
  block: BlockPageProgress,
  video: VideoPageProgress,
  quiz: QuizPageProgress,
  "sistemic-test": null,
};

function getPage(type) {
  return pageTemplateMap[type];
}

export {
  BasePageProgress,
  VideoPageProgress,
  AudioPageProgress,
  BlockPageProgress,
  QuizPageProgress,
  getPage,
  EndPage
};
