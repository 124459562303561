import React from "react";
import "./style.css";
import { capitalizeFirstLetter, newUUID, sleep } from "../../../dateutils/names";
import { getHoroscope } from "../../../hooks/calendar";

let signsIconsMap = {
  aries: "icon-aries",
  aquarius: "icon-aquarius",
  cancer: "icon-cancer",
  capricorn: "icon-capricorn",
  gemini: "icon-gemini",
  leo: "icon-leo",
  libra: "icon-libra",
  pisces: "icon-pisces",
  sagittarius: "icon-sagittarius",
  scorpio: "icon-scorpio",
  taurus: "icon-taurus",
  virgo: "icon-virgo",
};

class SignDetail extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.root = props.root;
    this.state = {};
  }

  render() {
    return (
      <div className="sign-detail">
        <h1>{capitalizeFirstLetter(this.data.sign)}</h1>
        <p>{this.data.detail}</p>
      </div>
    );
  }
}

class Horoscope extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.state = {
      horoscope: null,
      component: null,
      data: null,
    };
  }

  async componentDidMount() {
    let horoscopeData = await getHoroscope();
    if (horoscopeData) {
      this.setState(() => ({ horoscope: horoscopeData }));
    }
  }

  renderComponent(component, data) {
    this.setState(() => ({ component: component, data: data }));
  }

  async scrollToItem(id) {
    await sleep(200);
    let selector = `div[id="${id}"]`;
    let el = document.querySelector(selector);
    el.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }

  render() {
    if (this.state.component) {
      let Component = this.state.component;
      return (
        <div className="horoscope animated-intro">
          <h1>Horoscopo diario</h1>
          <div className="signs-container horizontal">
            {Object.entries(this.state.horoscope.signs).map(([key, value]) => {
              return (
                <div
                  id={key}
                  className={
                    "sign-container " +
                    (this.state.data.sign == key ? "selected" : "")
                  }
                  onClick={() => {
                    this.scrollToItem(key);
                    this.renderComponent(SignDetail, {
                      sign: key,
                      detail: value.data,
                    });
                  }}
                >
                  <img className={"icon " + signsIconsMap[key]}></img>
                  <p>{capitalizeFirstLetter(key)}</p>
                </div>
              );
            })}
          </div>
          <Component
            data={this.state.data}
            root={this}
            key={newUUID()}
          ></Component>
          <div className="nav-ops">
            <p>Explora sobre los Signos</p>
          </div>
        </div>
      );
    }

    return (
      <div className="horoscope animated-intro">
        <h1>Horoscopo diario</h1>
        {this.data && (
          <>
            <h2>{capitalizeFirstLetter(this.data.sign)}</h2>
            <p>{this.data.content}</p>
          </>
        )}
        {this.state.horoscope && !this.data && (
          <div>
            <div className="signs-container">
              {Object.entries(this.state.horoscope.signs).map(
                ([key, value]) => {
                  return (
                    <div
                      className="sign-container"
                      onClick={() => {
                        this.scrollToItem(key);
                        this.renderComponent(SignDetail, {
                          sign: key,
                          detail: value.data,
                        });
                      }}
                    >
                      <img className={"icon " + signsIconsMap[key]}></img>
                      <p>{capitalizeFirstLetter(key)}</p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}

        <div className="nav-ops">
          <p>Explora sobre los Signos</p>
        </div>
      </div>
    );
  }
}

export { Horoscope };
