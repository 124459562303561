import React from "react";
import { getCourses, getEnrolledCourses } from "../../hooks/courses";
import { APPO_STATUS_CODES } from "../Profile/constants";
import { MainCourseProgress } from "../EnrolledCourse";
import "./style.css";
import { Checkout } from "../../components/Checkout";
import { formatter, newUUID } from "../../dateutils/names";
import { PostManager } from "../../components/PostManager";

let tags = [
  {
    title: "Pareja",
    src: "/api/v1/images",
  },
  {
    title: "Auto-conocimiento",
    src: "/api/v1/images",
  },
  {
    title: "Infancia",
    src: "/api/v1/images",
  },
  {
    title: "Etica",
    src: "/api/v1/images",
  },
  {
    title: "Sexualidad",
    src: "/api/v1/images",
  },
  {
    title: "Tarot",
    src: "/api/v1/images"
  }
];

function catalogContainer(courses, root) {
  return (
    <div className="catalog-container animated-intro" key={newUUID()}>
      {courses &&
        courses.map((course) => {
          return (
            <div
              className="catalog-course-card"
              onClick={() => root.openCourse(course)}
            >
              <img src={course.image}></img>
              <div className="course-card-info">
                <h1>{course.name}</h1>
                <p>{"6 horas"}</p>
                <p className="course-card-tag">{course.tag || "Categoria"}</p>
              </div>
              <div className="course-card-price">
                <h1>
                  {course.plans &&
                    course.plans.length > 0 &&
                    formatter.format(course.plans[0].price, true)}
                </h1>
                <div className="rating">
                  <img className="icon"></img>
                  <img className="icon"></img>
                  <img className="icon"></img>
                  <img className="icon"></img>
                  <img className="icon"></img>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

class Courses extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;
    this.state = {
      courses: null,
      filter: this.data.filter
    };
  }

  openCourse(data) {
    data["courses"] = this.state.courses;
    this.root.setComponent(Course, null, data, null);
  }

  async getCourses() {
    let [courses, accData] = await getCourses();
    if (courses) {
      this.setState(() => ({ courses: courses }));
    }
  }

  render() {
    if (!this.state.courses) {
      this.getCourses();
      return <></>
    }

    let filteredCourses;
    if (this.state.filter && this.state.courses && this.state.courses.filter) {
      filteredCourses = this.state.courses.filter((course)=>{
        if (course.tag == this.state.filter) {
          return course;
        }
      })
    }

    return (
      <div className="courses-catalog animated-intro">
        <h1>Cursos Digitales</h1>
        <p>
        Transforma tu vida con herramientas prácticas para el equilibrio mental y emocional.
        </p>
        <div className="course-tags-container">
          {tags.map((tag) => {
            return (
              <div key={newUUID()} className={"animated-intro tag-item " + (this.state.filter && this.state.filter == tag.title.toLowerCase() ? "active": "")} onClick={()=>{
                this.setState(()=>({filter: tag.title.toLowerCase()}))
              }}>
                <img className={`icon icon-${tag.title.toLowerCase()}`}></img>
                <p>{tag.title}</p>
              </div>
            );
          })}
        </div>
        {catalogContainer(filteredCourses ? filteredCourses : this.state.courses, this)}
      </div>
    );
  }
}

class Congrats extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
  }
  async close() {
    this.root.setState(() => ({ congrats: false, congratsLock: true }));
  }

  async getEnrolledCourse() {
    let enrolledCourses = await getEnrolledCourses();
    enrolledCourses.forEach((course)=>{
      if (course._id == this.root.data._id) {
        console.log(course);
        this.root.root.setComponent(MainCourseProgress , null, course);
      }
    })
    // 
  }

  render() {
    return (
      <div className="congrats-page animated-intro">
        <img></img>
        <div>
          <h1>¡¡Felicidades!!</h1>
          <p>Adquiriste este Curso y puedes empezar tu aprendizaje!!</p>
        </div>
        <button onClick={() => this.getEnrolledCourse()}>Continuar</button>
      </div>
    );
  }
}

class Course extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.root = props.root;
    this.courses = props.data.courses;
    this.state = { congrats: false, congratsLock: false };
  }

  openCourse(data) {
    data["courses"] = this.courses;
    this.root.setComponent(Course, null, data, null);
  }

  async share() {
    await navigator.share({
      title: `Curso ${this.data.name}`,
      text: this.data.name,
      url: `${location.protocol}//${location.host}/?section=courses&id=${this.data._id}`,
    });
  }

  payCourse() {
    this.data["courses"] = this.courses;
    this.root.setComponent(
      Checkout,
      null,
      { entityType: "courses", entityId: this.data._id },
      null
    );
  }

  checkAction() {
    let action = this.root.queryparams.get("action");
    if (action == "purchase") {
      this.root.queryparams.delete("action");
      this.payCourse();
    }
  }

  openCongrats() {
    this.setState(() => ({ congrats: true, congratsLock: false }));
  }

  courseHeader() {
    return (
      <div className="course-header">
        <img src={this.data.image}></img>
        <div className="course-header-info">
          <div className="header-block">
            <h1>{this.data.name}</h1>
            <div className="share-button" onClick={() => this.share()}>
              <p>Compartir</p>
              <img className="icon"></img>
            </div>
          </div>
          <div className="header-block duration">
            <div className="header-duration">
              <h1>6 Horas</h1>
              <p className="course-card-tag">{this.data.tag || "Categoria"}</p>
            </div>
            {this.data.purchase &&
            this.data.purchase.status ==
              APPO_STATUS_CODES.PAID_UNCONFIRMED.code ? (
              <div className="start-button">
                <p>Empezar!</p>
              </div>
            ) : (
              <div className="purchase-button" onClick={() => this.payCourse()}>
                <p>
                  Comprar{" "}
                  <span>
                    {this.data.plans &&
                      this.data.plans.length > 0 &&
                      formatter.format(this.data.plans[0].price, true)}{" "}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    this.checkAction();
    if (
      this.data.purchase &&
      !this.state.congrats &&
      !this.state.congratsLock
    ) {
      this.openCongrats();
    }
    return (
      <div className="course-content-container animated-intro">
        {this.data.purchase && this.state.congrats ? (
          <>
            <Congrats root={this}></Congrats>
            {this.courseHeader()}
          </>
        ) : (
          <>
            {this.courseHeader()}
            <div className="course-description">
              <h1>Descripcion</h1>
              <pre>{this.data.description}</pre>

              <h1>Modulos</h1>
              {this.data.modules &&
                <div className="modules-card-container">
                  {
                    this.data.modules.map((module, index) => {
                      return (
                        <div className="course-module-card">
                          <div className="module-card-header">
                            <p>Modulo {index + 1}</p>
                            <img className="icon" src={module.icon}></img>
                            <h1>{module.title}</h1>
                          </div>
                          <p>{module.description}</p>
                        </div>
                      );
                    })
                  }
                </div>  
                
              }
              {this.data.purchase &&
              this.data.purchase.status ==
                APPO_STATUS_CODES.PAID_UNCONFIRMED.code ? (
                <div className="start-button">
                  <p>Empezar curso Ahora!</p>
                </div>
              ) : (
                <div
                  className="purchase-button"
                  onClick={() => this.payCourse()}
                >
                  <p>
                    ¡Comprar Ahora por {" "}
                    <span>
                      {this.data.plans &&
                        this.data.plans.length > 0 &&
                        formatter.format(this.data.plans[0].price, true)}{" "}
                    </span>
                  </p>
                </div>
              )}
              <br></br>
              <PostManager data={this.data} entity="courses"></PostManager>
              <br></br>
              <h1>Ofertas Similares</h1>
              {catalogContainer(this.courses, this)}
            </div>
          </>
        )}
        <div className="nav-ops">
          <p onClick={()=>{
            this.root.setComponent(Courses, null, {})
          }}>Regresar</p>
        </div>
      </div>
    );
  }
}

class CourseInProgress extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.state = {
      module: 0,
      page: 0,
      currentState: this.data.modules.map((module) => {
        return { page: 0, total: module.pages.length };
      }),
    };
  }

  updateCurrentState(module, page) {
    this.setState((p) => {
      let state = p.currentState;
      state[module].page = page;
    });
  }

  renderPage(pageData) {
    // // console.log(pageData);
  }

  render() {
    return <div></div>;
  }
}

export { Courses, Course };
