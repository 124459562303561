import React from "react";
import "./style.css";
import { EndPage, getPage } from "./Pages";
import { newUUID } from "../../../dateutils/names";

class ModuleManager extends React.Component {
  constructor(props) {
    super(props);
    this.root = props.root;
    this.data = props.data;

    let module = this.root.state.courseProgress.modules[this.data.index - 1];

    if (module) {
      this.state = {
        maxPage: module.maxPage || 0,
        currentPage: module.maxPage || module.currentPage || 0,
        pages: this.data.pages,
      };
    } else {
      this.state = {
        maxPage: 0,
        currentPage: 0,
        pages: this.data.pages,
      };
    }
  }

  currentPage() {
    console.log(this.state);
    console.log(this.root.state.courseProgress);
    let currentPage = this.data.pages[this.state.currentPage];

    if (this.state.currentPage >= this.data.pages.length) {
      return <EndPage key={newUUID()} root={this} data={{}}></EndPage>;
    }
    let PageComponent = getPage(currentPage.type);
    return (
      <PageComponent
        key={newUUID()}
        root={this}
        data={currentPage}
      ></PageComponent>
    );
  }

  render() {
    return <div className="module-manager">{this.currentPage()}</div>;
  }
}

export { ModuleManager };
